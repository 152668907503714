var BraintreeAuthorizationHelper = {
  methods: {
    braintreeClientAuthorization() {
      let token_dom = document.querySelector(
        'meta[name="braintree-client-auth"]'
      );
      let token = "";

      if (token_dom) {
        token = token_dom.content;
      }

      return token;
    }
  }
};

export default BraintreeAuthorizationHelper;
