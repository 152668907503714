document.addEventListener("DOMContentLoaded", () => {
  var countDowns = $("[data-countdown-minutes]");
  if (countDowns.length) {
    countDowns.each(function() {
      var element = $(this);
      var countDownDate =
        new Date().getTime() + element.data("countdown-minutes") * 60000;

      var secondsInterval = setInterval(function() {
        var difference = countDownDate - new Date().getTime();
        var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((difference % (1000 * 60)) / 1000);

        element.text("0" + minutes + ":" + ("0" + seconds).slice(-2));

        if (difference < 0) {
          $(".countdown").remove();
        }
      }, 1000);
    });
  }
});
