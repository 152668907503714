import Vue from "vue/dist/vue.esm";
import CsrfHelper from "../mixins/csrf_helper.js";
import BraintreeAuthorizationHelper from "../mixins/braintree_authorization_helper.js";
import ahoy from "ahoy.js";

document.addEventListener("DOMContentLoaded", () => {
  if ($("#upsells-page").length) {
    initializeUpsells();
  }
});

function initializeUpsells() {
  new Vue({
    el: "#upsells-page",
    mixins: [CsrfHelper, BraintreeAuthorizationHelper],
    data: {
      maxQueue: 270,
      minQueue: 380,
      minutes: 10,
      hoursLeft: "",
      secondsLeft: "",
      minutesLeft: "",
      countDownDate: {},
      isBottom: false,
      currentEta: "22h - 26h",
      slotsLeft: 4,
      interval: null,
      steps: [],
      localizedSteps: [],
      step: 0,
      cart: [],
      hideTimer: false,
      processingPayment: false,
      navHeight: 0,
      isLoading: false,
      expeditedDelivery: false,
      skipUpsells: true,
      enableUpsells: false,
      checkoutType: "",
      deviceData: null,
      upsellNames: {
        special_offer_desserts: "Keto Desserts",
        special_offer_workout: "Workout Plan",
        special_offer_guide: "Ultimate Keto Guide",
        special_offer_express: "Express Delivery"
      }
    },

    created: async function() {
      // this.sendThankYouGTMEvent();
      this.setCheckoutType();

      const response = await fetch("/api/upsell_plans", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const responseData = await response.json();
      this.localizedSteps = responseData.plans;
      this.enableUpsells =
        responseData.upsells === true &&
        !window.location.toString().includes("thank_you");

      this.steps = this.formatUpsellsKey(responseData.plans);
      this.steps.forEach(plan => {
        this.countDownDate[plan] = null;
      });
      this.steps.push("thank_you");

      this.checkPermissions();
    },

    computed: {
      getQueueNumber() {
        return Math.floor(
          Math.random() * (this.maxQueue - this.minQueue + 1) + this.minQueue
        );
      },

      showStickyBar() {
        return !this.isBottom;
      },

      isDeliveryUpsell() {
        return this.step === this.steps.indexOf("special_offer_express");
      },

      isDessertUpsell() {
        return this.step === this.steps.indexOf("special_offer_desserts");
      },

      isWorkoutUpsell() {
        return this.step === this.steps.indexOf("special_offer_workout");
      },

      isGuideUpsell() {
        return this.step === this.steps.indexOf("special_offer_guide");
      },

      currentStep() {
        return this.steps[this.step];
      },

      currentLocalizedStep() {
        return this.localizedSteps[this.step];
      }
    },

    methods: {
      formatUpsellsKey(plans) {
        return plans.map(plan => this.formatUpsellKey(plan));
      },
      formatUpsellKey(plan) {
        return plan
          .replace(/\_eur/g, "")
          .replace(/\_gbp/g, "")
          .replace(/\_aud/g, "");
      },
      // sendThankYouGTMEvent() {
      //   if (window.dataLayer) {
      //     window.dataLayer.push({
      //       event: "thank_you_page"
      //     });
      //     ahoy.track("thank_you_page_event");
      //   }
      // },
      sendUpsellConversionGTMEvent(response) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "upsellPurchase",
            value: response.amount * 0.01,
            currency: response.currency,
            transactionId: response.order_code
          });
          ahoy.track(response.currency, {
            value: response.amount * 0.01,
            currency: response.currency,
            transactionId: response.order_code
          });
        }
      },
      checkPermissions() {
        const params = new URLSearchParams(window.location.search);
        const paramKey = params.get("test_upsells");

        if (!this.enableUpsells && paramKey !== "true") {
          this.step = this.steps.length - 1;
          this.updateURL();
        } else {
          this.skipUpsells = false;

          this.setCountDownDate(this.currentStep);
          this.interval = setInterval(this.countdown, 100);
        }
      },
      doubleDigits(date) {
        return date < 10 ? "0" + date : date;
      },

      countdown() {
        const difference =
          this.countDownDate[this.currentStep] - new Date().getTime();
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        this.hoursLeft = "00 :";
        this.minutesLeft = this.doubleDigits(minutes) + " :";
        this.secondsLeft = this.doubleDigits(seconds);
        if (difference <= 0) {
          this.hideTimer = true;
        }
      },

      onScroll() {
        if (
          $(window).scrollTop() + $(window).height() + this.navHeight >=
          $(document).height()
        ) {
          this.isBottom = true;
        } else this.isBottom = false;
      },

      addToCart() {
        if (!this.cart.includes(this.currentLocalizedStep)) {
          this.cart.push(this.currentLocalizedStep);
        }

        this.nextUpsell();
      },

      removeUpsell() {
        if (this.cart.includes(this.currentLocalizedStep)) {
          this.cart = this.cart.filter(
            item => item !== this.currentLocalizedStep
          );
        }

        this.nextUpsell();
      },

      async nextUpsell() {
        clearInterval(this.interval);
        this.step++;
        this.hideTimer = false;

        if (this.currentStep === "thank_you" && this.cart.length > 0) {
          this.processingPayment = true;
          const response = await fetch("/meal-plan/checkout", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.findCsrfToken()
            },
            body: JSON.stringify({
              payment_plans: this.cart,
              checkout_type: this.checkoutType,
              device_data: this.deviceData,
              charge_type: "upsell_payment"
            })
          });
          const responseData = await response.json();
          if (response.ok) {
            this.processingPayment = false;

            if (this.cart.includes("special_offer_express")) {
              this.expeditedDelivery = true;
            }

            if (responseData.details)
              this.sendUpsellConversionGTMEvent(responseData.details);
          } else {
            alert("Oppps, Unable to create the subscription at this moment");
          }
        } else {
          this.isLoading = true;
          this.setCountDownDate(this.currentStep);
          this.interval = setInterval(this.countdown, 100);
        }

        this.updateURL();

        setTimeout(() => {
          this.isLoading = false;
        }, 600);
      },

      updateURL() {
        history.pushState({ step: this.step }, "", this.currentStep);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant"
        });
      },

      setCountDownDate(currentStep) {
        if (localStorage.getItem(currentStep)) {
          this.countDownDate[currentStep] = localStorage.getItem(currentStep);
        } else {
          this.countDownDate[currentStep] =
            new Date().getTime() + this.minutes * 60000;
          localStorage.setItem(currentStep, this.countDownDate[currentStep]);
        }
      },

      setCheckoutType() {
        const params = new URLSearchParams(window.location.search);
        this.checkoutType = params.get("checkout_type");
      },

      async initializeDeviceDataCollector() {
        if (this.checkoutType != "paypal") {
          return;
        }

        let deviceData;
        await braintree.client
          .create({
            authorization: this.braintreeClientAuthorization()
          })
          .then(function(clientInstance) {
            return braintree.dataCollector.create({ client: clientInstance });
          })
          .then(function(dataCollectorInstance) {
            deviceData = JSON.parse(dataCollectorInstance.deviceData);
          });
        this.deviceData = deviceData.correlation_id;
      }
    },

    async mounted() {
      await this.initializeDeviceDataCollector();

      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        this.navHeight = 200;
      }

      window.addEventListener("scroll", this.onScroll);

      window.onpopstate = () => {
        if (this.currentStep === "thank_you") {
          window.location.href = window.location.origin;
        } else {
          this.step--;
          this.interval = setInterval(this.countdown, 100);
        }
      };
    }
  });
}
