import Vue from "vue/dist/vue.esm";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import i18n from "i18n-js";

var mask = require("jquery-mask-plugin");

Vue.use(Vuelidate);
Vue.use(i18n);

$(document).ready(function() {
  const $forms = $(".plan-selector > .radio-selector-input");
  if ($forms.length) {
    for (let x = 0; x < $forms.length; x++) {
      const creditElement = `#creditCheckoutFormWrapper_${$forms[x].value}`;
      const paypalElement = `#paypalCheckoutFormWrapper_${$forms[x].value}`;

      if ($(creditElement).length) initializeCheckoutForm(creditElement);
      if ($(paypalElement).length) initializeCheckoutForm(paypalElement);
    }

    window.initializeCheckoutForm = initializeCheckoutForm;

    const checkoutForm = {
      init: function() {
        $("input").removeClass("is-valid");
        if ($("#checkout")) this.setActions();

        $(".credit-card-form-submit-button").click(function() {
          $(".alert-danger").remove();
        });
      },

      setActions: function() {
        $("[data-mask=creditCard]").mask("0000 0000 0000 0000", {});
        $("[data-mask=expDate]").mask("0Z/0000", {
          translation: { Z: { pattern: /[0-9]/, optional: true } }
        });
        $("[data-mask=cvv]").mask("0000", {});
      }
    };
    checkoutForm.init();
    window.checkoutForm = checkoutForm;
  }
});

function initializeCheckoutForm(element, userEmail = null) {
  new Vue({
    el: element,
    data: {
      email: userEmail,
      paramKey: null,
      i18n: i18n,
      errors: {
        email: {
          required: `Email ${i18n.t("errors.messages.blank")}`,
          invalid: `Email ${i18n.t("errors.messages.invalid")}`
        }
      }
    },
    created() {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      this.paramKey = params.get("upsells_test");
    },
    validations() {
      return {
        email: { required, email }
      };
    },
    computed: {
      emailInvalidClass() {
        return this.$v.email.$error
          ? ["form-invalid", "form-group-invalid", "is-invalid"]
          : ["", "", ""];
      },
      emailTextError() {
        if (this.$v.email.$invalid && !this.$v.email.required)
          return `${this.errors.email.required}${this.i18n.t(
            "support.array.two_words_connector"
          )}${this.errors.email.invalid}`;
        else if (this.$v.email.$invalid) return this.errors.email.invalid;
        else if (!this.$v.email.$required) return this.errors.email.required;
        else return "";
      }
    },
    methods: {
      resetApiError() {
        const $invalidFeedbackApi = $(
          ".payment-modal.show .checkout_form_email > .invalid-feedback"
        );
        const $invalidFormFe = $(".payment-modal.show .form-invalid");

        if ($invalidFeedbackApi.length || !$invalidFormFe.length) {
          const $formEmailInput = $(".payment-modal.show #checkout_form_email");
          const $formEmailGroup = $(".payment-modal.show .checkout_form_email");

          $formEmailInput.removeClass("is-invalid");
          $formEmailGroup.removeClass("form-group-invalid");
          $invalidFeedbackApi.remove();
        }
      }
    }
  });
}
