$(document).ready(function() {
  $(".input-group-text").click(function() {
    if ($("#user_password").attr("type") == "password") {
      $(this)
        .find("i")
        .removeClass("fa-eye");
      $(this)
        .find("i")
        .addClass("fa-eye-slash");
      $("#user_password").attr("type", "text");
    } else {
      $("#user_password").attr("type", "password");
      $(this)
        .find("i")
        .removeClass("fa-eye-slash");
      $(this)
        .find("i")
        .addClass("fa-eye");
    }
  });

  (function() {
    "use strict";
    var forms = document.querySelectorAll(".needs-validation");
    Array.prototype.slice.call(forms).forEach(function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  })();
});
