$(document).ready(function() {
  $(".image-replace").each(function() {
    var imageUrl = $(this)
      .find("img")
      .attr("src");

    $(this)
      .find("img")
      .remove();

    $(this).css("background-image", `url(${imageUrl})`);
  });
});
