import Vue from "vue/dist/vue.esm";
import CsrfHelper from "./csrf_helper";
import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);

var ReplaceHelper = {
  mixins: [CsrfHelper],

  data: function() {
    return {
      csrfToken: this.findCsrfToken(),
      availableRecipes: [],
      mealType: null,
      slug: null,
      page: 1,
      favouritesPage: 1,
      showLoader: false,
      isPlanPage: false,
      difficulties: ["Unknown", "Easy", "Medium", "Complicated"],
      active: null,
      toggleCTA: false,
      isFavouritesEmpty: false,
      isRecommendedEmpty: false,
      showScroll: false,
      selectedRecipe: null,
      blur: false,
      $scrollList: null,
      isDetailedView: false,
      recipeDetails: null,
      initialPos: 0,
      isFavoritesTab: false,
      paramsObj: null,
      favouriteReplacements: [],
      retrieveFavList: false,
      search: "",
      timeout: null,
      searchResults: [],
      retrieveSearchList: false,
      openFilters: false,
      filtersActive: false,
      dificultyFilters: [
        {
          name: "Easy",
          value: "eq-1"
        },
        {
          name: "Medium",
          value: "eq-2"
        },
        {
          name: "Hard",
          value: "eq-3"
        }
      ],
      timeFilters: [
        {
          name: "Less than 10 mins",
          value: "lte-10"
        },
        {
          name: "Less than 15 mins",
          value: "lte-15"
        },
        {
          name: "Less than 20 mins",
          value: "lte-20"
        },
        {
          name: "More than 20 mins",
          value: "gte-21"
        }
      ],
      calorieFilters: [
        {
          name: "Less than 200",
          value: "lt-200"
        },
        {
          name: "Between 201 and 300",
          value: "gte-201,lte-300"
        },
        {
          name: "Between 301 and 400",
          value: "gte-301,lte-400"
        },
        {
          name: "Between 401 and 500",
          value: "gte-401,lte-500"
        },
        {
          name: "More than 500",
          value: "gte-501"
        }
      ],
      vegetablesFilters: [],
      otherIngredientsFilters: [],
      proteinFilters: [],
      selectedFilters: {
        timeFilters: [],
        dificultyFilters: [],
        calorieFilters: null,
        vegetablesFilters: [],
        otherIngredientsFilters: [],
        proteinFilters: []
      },
      filterResults: []
    };
  },

  watch: {
    isFavoritesTab(value) {
      this.cancel();

      if (value) {
        this.paramsObj.favourite = true;
        this.paramsObj.page = this.favouritesPage;
        this.getReplacementRecipes();
      } else {
        delete this.paramsObj.favourite;
        this.paramsObj.page = this.page;
      }
    },

    search(value) {
      this.cancel();

      if (!value.length) {
        this.retrieveSearchList = false;
        this.searchResults = [];
      }
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleSearch(value);
      }, 400);
    }
  },

  computed: {
    isSelected() {
      return this.active;
    },

    replacementRecipes() {
      if (this.search.length) {
        return this.searchResults;
      } else if (this.filtersActive) {
        return this.filterResults;
      }

      return this.isFavoritesTab
        ? this.favouriteReplacements
        : this.availableRecipes;
    },

    showEmptyListMessage() {
      return (
        this.isFavoritesTab &&
        !this.favouriteReplacements.length &&
        this.retrieveFavList
      );
    },

    emptyResults() {
      if (!this.search.length) {
        return this.isFavoritesTab
          ? this.isFavouritesEmpty
          : this.isRecommendedEmpty;
      }
      return !this.searchResults.length && this.retrieveSearchList;
    },

    showEmptyMessages() {
      if (this.filtersActive && !this.filterResults.length) {
        return true;
      }

      return this.emptyResults;
    }
  },

  created: function() {
    const location = window.location;
    const params = new URLSearchParams(location.search.substring(1));
    const href = location.pathname;
    const recipesRegexp = /recipes(.*)/;
    const plansRegexp = /weekly_plans/;

    if (href.match(plansRegexp)) {
      this.isPlanPage = true;
    } else {
      this.slug = recipesRegexp.exec(href)[1].replace("/", "");
      this.mealType = params.get("type");
    }

    this.getFilterData();
  },

  methods: {
    handleFocusOut(event) {
      const $filters = document.querySelector(".filters").innerHTML;
      const $clickedElement = event.target.outerHTML;
      if (!$filters.includes($clickedElement)) {
        this.openFilters = false;
      }
    },

    addFavourite(event, recipeId) {
      event.stopPropagation();

      fetch(`/recipes/${recipeId}/favourite`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.csrfToken,
          Accept:
            "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01"
        }
      }).then(async response => {
        this.replacementRecipes.forEach(el => {
          if (el.id === recipeId) {
            el.favourite = true;
          }
        });
        const script = document.createElement("script");
        script.innerHTML = await response.text();
        document.body.appendChild(script);
      });
    },

    removeFavourite(event, recipeId) {
      event.stopPropagation();

      fetch(`/recipes/${recipeId}/favourite`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.csrfToken,
          Accept:
            "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01"
        }
      }).then(async response => {
        this.replacementRecipes.forEach(el => {
          if (el.id === recipeId) {
            el.favourite = false;
          }
        });
        if (this.isFavoritesTab) {
          this.availableRecipes.forEach(el => {
            if (el.id === recipeId) {
              el.favourite = false;
            }
          });
        }
        const script = document.createElement("script");
        script.innerHTML = await response.text();
        document.body.appendChild(script);
      });
    },

    getFilterData() {
      fetch(`/api/filter_recipes/filter_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        response.json().then(result => {
          this.proteinFilters = result.proteins.sort();
          this.proteinFilters = this.proteinFilters.map(el => {
            return {
              name: el,
              value: el
            };
          });
          this.otherIngredientsFilters = result.other_ingredients.sort();
          this.otherIngredientsFilters = this.otherIngredientsFilters.map(
            el => {
              return {
                name: el,
                value: el
              };
            }
          );
          this.vegetablesFilters = result.vegetables.sort();
          this.vegetablesFilters = this.vegetablesFilters.map(el => {
            return {
              name: el,
              value: el
            };
          });
        });
      });
    },

    handleSearch(search) {
      if (search.length) {
        this.showLoader = true;
        const params = {
          recipe_id: this.slug,
          type: this.mealType,
          search: search
        };
        fetch(`/api/search_recipes?${new URLSearchParams(params)}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }).then(response => {
          response.json().then(result => {
            this.searchResults = [...result];
            this.showLoader = false;
            this.retrieveSearchList = true;
          });
        });
      }
    },

    onModalScroll() {
      if (!this.isDetailedView) {
        this.showScroll = false;
        if (!this.emptyResults && !this.search.length && !this.filtersActive) {
          let isBottom =
            Math.round(
              this.$scrollList.scrollTop() + this.$scrollList.innerHeight()
            ) >= this.$scrollList.prop("scrollHeight");
          if (isBottom && !this.isFavoritesTab) {
            this.page++;
            this.paramsObj.page = this.page;
            this.getReplacementRecipes();
          }
        }
      }
    },

    formatInstructions(instructions) {
      return instructions.split(/\s*\d\.\s*/);
    },

    getReplacementRecipes() {
      this.showLoader = true;

      fetch(`/api/similar_recipes?${new URLSearchParams(this.paramsObj)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        response.json().then(result => {
          if (this.isFavoritesTab) {
            this.favouriteReplacements = [...result];
            this.retrieveFavList = true;
            if (!result.length) {
              this.isFavouritesEmpty = true;
            }
          } else {
            this.availableRecipes.push(...result);
            if (!result.length) {
              this.isRecommendedEmpty = true;
            }
          }
          this.showLoader = false;
        });
      });
    },

    loadNewRecipe() {
      this.blur = true;
    },

    addBackground(meal) {
      if (
        window.location.host.includes("localhost") ||
        window.location.host.includes("develop")
      ) {
        return `background-image: url(/assets/bg/lost_recipe_image-c79505fb61be577608ae1c473a199df92ebb37431b0a3cf1275906e7b1dff4d3.jpg)`;
      }
      return `background-image: url(${meal.picture_url})`;
    },

    getDifficulty(value) {
      return this.difficulties[value];
    },

    roundValue(value) {
      return Math.round(value * 10) / 10;
    },

    cancel() {
      this.active = null;
      this.toggleCTA = false;
    },

    closeModal() {
      this.cancel();
      this.availableRecipes = [];
      this.favouriteReplacements = [];
      this.searchResults = [];
      this.search = "";
      this.retrieveSearchList = false;
      this.page = 1;
      this.favouritesPage = 1;
      this.isFavouritesEmpty = false;
      this.isRecommendedEmpty = false;
      this.isFavoritesTab = false;
      this.retrieveFavList = false;
      this.resetFilters();
      this.$scrollList.off("scroll", this.onModalScroll);
    },

    expandMeal(recipe, idx) {
      this.initialPos = this.$scrollList.scrollTop();
      this.isDetailedView = true;
      this.showScroll = false;
      this.recipeDetails = recipe;
      this.toggleCTA = true;
      this.selectedRecipe = recipe.id;
      this.active = idx;
      this.$scrollList.scrollTop(0);
    },

    showCTA(idx, recipeId) {
      this.active = idx;
      this.toggleCTA = true;
      this.selectedRecipe = recipeId;
      this.showScroll = false;
    },

    replaceRecipe(event) {
      if (this.isPlanPage) {
        let $recipe;
        event.stopPropagation();
        event.preventDefault();

        $recipe = $(event.target.closest(".day"));
        this.mealType = $recipe
          .find(".meal__plan__meal-type")
          .text()
          .trim();
        this.slug = $recipe
          .find(".slug")
          .text()
          .trim();
        this.$scrollList = $recipe.find(".replace-modal .modal-body");
      } else {
        this.$scrollList = $(".modal-body");
      }

      this.paramsObj = {
        recipe_id: this.slug,
        type: this.mealType,
        page: this.page
      };
      this.getReplacementRecipes();
      this.showScroll = true;
      this.$scrollList.on("scroll", this.onModalScroll);
    },

    exitDetailedView() {
      this.isDetailedView = false;
      this.recipeDetails = null;
      this.toggleCTA = false;
      const pixels = this.initialPos;
      Vue.nextTick(() => {
        this.$scrollList.scrollTop(pixels);
      });
    },

    resetFilters() {
      this.selectedFilters.timeFilters = [];
      this.selectedFilters.dificultyFilters = [];
      this.selectedFilters.calorieFilters = null;
      this.selectedFilters.vegetablesFilters = [];
      this.selectedFilters.otherIngredientsFilters = [];
      this.selectedFilters.proteinFilters = [];
      this.filtersActive = false;
      this.openFilters = false;
    },

    createUrlParams() {
      let url = `/api/filter_recipes?recipe_id=${this.slug}&type=${this.mealType}`;

      this.selectedFilters.proteinFilters.forEach(el => {
        url = `${url}&protein_filters[]=${el}`;
      });
      this.selectedFilters.vegetablesFilters.forEach(el => {
        url = `${url}&vegetables_filters[]=${el}`;
      });
      this.selectedFilters.otherIngredientsFilters.forEach(el => {
        url = `${url}&other_ingredients_filters[]=${el}`;
      });
      this.selectedFilters.dificultyFilters.forEach(el => {
        url = `${url}&difficulty_filters[]=${el}`;
      });
      this.selectedFilters.timeFilters.forEach(el => {
        url = `${url}&time_filters[]=${el}`;
      });

      if (this.selectedFilters.calorieFilters) {
        url = `${url}&calorie_filters[]=${this.selectedFilters.calorieFilters}`;
      }

      return url;
    },

    applyFilters() {
      const url = this.createUrlParams();

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        response.json().then(result => {
          this.filterResults = [...result];
          this.openFilters = false;
          this.filtersActive = true;
        });
      });
    }
  }
};

export default ReplaceHelper;
