document.addEventListener("DOMContentLoaded", () => {
  if ($(".sell-page").length) {
    if (window.performance.navigation.type === 2) {
      const checkoutURL = window.location.origin + "/checkout/thank_you";
      window.location.href = checkoutURL;
    }
  }

  $(".plan-selector").on("change", function() {
    var value = $('input[name="plan"]:checked').val();
    $('.open-plan-form > input[name="payment_plan_key"]').val(value);
    $(".plan-disclaimer").addClass("hidden");
    $(`.plan-disclaimer-${value}`).removeClass("hidden");
  });

  function enableCreditCardTab() {
    if (window.location.hash === "#paypal") enablePayPalTab();
    else {
      $(".credit-tab").click();
      $(".credit-tab").addClass("active-payment-method-button");
    }
  }

  function enablePayPalTab() {
    $(".paypal-tab").click();
    $(".paypal-tab").addClass("active-payment-method-button");
  }

  function continuePaypal(isSuccessful) {
    $paypal = $(".paypal-container");
    $continueButton = $(".use-email-button");

    if (isSuccessful) {
      $(".norton-logo").addClass("mt-15");
      $paypal.removeClass("hide-paypal-button");
      $continueButton.prop("disabled", true);
      $continueButton.hide();
      $(".email-confirmed-div").removeClass("d-none");
      $(".paypal-tagline-div").removeClass("d-none");
    } else {
      $(".norton-logo").removeClass("mt-15");
      $paypal.addClass("hide-paypal-button");
      $(".paypal-tagline-div").addClass("d-none");
      $(".email-confirmed-div").addClass("d-none");
      $continueButton.prop("disabled", false);
    }
  }

  window.enableCreditCardTab = enableCreditCardTab;
  window.continuePaypal = continuePaypal;
});
