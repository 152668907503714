import Vue from "vue/dist/vue.esm";
import ReplaceHelper from "../mixins/replace_helper.js";

document.addEventListener("DOMContentLoaded", () => {
  if ($("#replaceRecipe").length) {
    initializeReplace();
  }
});

function initializeReplace() {
  new Vue({
    el: "#replaceRecipe",
    mixins: [ReplaceHelper]
  });
}
