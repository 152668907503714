<template>
  <div></div>
</template>

<script>
// MIXINS
// For grabbing the CSRF token to be used to submit to internal API endpoint
import CsrfHelper from "../mixins/csrf_helper.js";
import BraintreeAuthorizationHelper from "../mixins/braintree_authorization_helper";

export default {
  mixins: [CsrfHelper, BraintreeAuthorizationHelper],
  props: {
    refer: {
      type: String,
      required: true
    },
    paypalPlanId: {
      type: String,
      required: true
    },
    paymentPlanId: {
      type: String,
      required: true
    }
    // This is where will pass more product attributes to be used in this vue component from the rails html template
  },
  data() {
    return {
      deviceData: null
    };
  },
  mounted: async function() {
    // IMPORTANT: this method causes the paypal button be loaded and rendered
    await this.initializeDeviceDataCollector();
    this.setLoaded();
  },
  computed: {
    selectorContainer() {
      return "#" + this.refer;
    }
  },
  methods: {
    setLoaded: async function() {
      braintree.client
        .create({
          authorization: this.braintreeClientAuthorization()
        })
        .then(function(clientInstance) {
          return braintree.paypalCheckout.create({
            client: clientInstance
          });
        })
        .then(this.loadPayPalSKD)
        .then(this.initPayPalButton);
    },

    loadPayPalSKD(paypalCheckoutInstance) {
      return paypalCheckoutInstance.loadPayPalSDK({
        vault: true,
        dataAttributes: { namespace: "paypalSDK" }
      });
    },

    initPayPalButton(paypalCheckoutInstance) {
      let that = this;
      return paypalSDK
        .Buttons({
          fundingSource: paypalSDK.FUNDING.PAYPAL,

          createBillingAgreement: function() {
            return paypalCheckoutInstance.createPayment({ flow: "vault" });
          },

          onApprove: function(data) {
            return paypalCheckoutInstance
              .tokenizePayment(data)
              .then(async function(payload) {
                const response = await fetch("/meal-plan/checkout", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": that.findCsrfToken()
                  },
                  body: JSON.stringify({
                    paypal_response: payload,
                    checkout_type: "paypal",
                    charge_type: "billing_agreement",
                    email: $(".paypal_email_input:visible").val(),
                    device_data: that.deviceData
                  })
                });
                if (response.ok) {
                  let responseData = await response.json();
                  if (window.dataLayer) {
                    window.dataLayer.push({
                      event: "thank_you_page",
                      currency: responseData.users_payment_plan.currency,
                      value:
                        responseData.users_payment_plan.amount_cents * 0.01,
                      transactionId: responseData.users_payment_plan.order_code
                    });
                  }
                  window.location = responseData.url;
                } else {
                  alert(
                    "Oppps, Unable to create the subscription at this moment"
                  );
                }
              });
          },

          onCancel: function(data) {
            console.log("PayPal payment canceled", JSON.stringify(data, 0, 2));
          },

          onError: function(err) {
            console.error("PayPal error", err);
          }
        })
        .render(this.selectorContainer);
    },

    async initializeDeviceDataCollector() {
      let deviceData;
      await braintree.client
        .create({
          authorization: this.braintreeClientAuthorization()
        })
        .then(function(clientInstance) {
          return braintree.dataCollector.create({ client: clientInstance });
        })
        .then(function(dataCollectorInstance) {
          deviceData = JSON.parse(dataCollectorInstance.deviceData);
        });
      this.deviceData = deviceData.correlation_id;
      console.log("deviceData", this.deviceData);
    }
  }
};
</script>
