import Vue from "vue/dist/vue.esm";
import { unpackSDKMeta } from "@paypal/sdk-client";
import PaypalButton from "../components/paypal_button.vue";

document.addEventListener("DOMContentLoaded", () => {
  const paypalVueComponent = {
    init: function(elementId) {
      new Vue({
        el: "#" + elementId, // Add the css identifier for the div that will load the paypal button
        components: {
          PaypalButton
        }
      });
    }
  };

  // make this globally acessible so that it can be called from anywhere
  window.paypalVueComponent = paypalVueComponent;
});
