import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "accounts": {
    "show": {
      "title": "Account"
    },
    "user_menu": {
      "account": "Account",
      "sign_out": "Sign out",
      "weekly_plan": "cw Plan"
    }
  },
  "active_admin": {
    "access_denied": {
      "message": "You are not authorized to perform this action."
    },
    "any": "Any",
    "batch_actions": {
      "action_label": "%{title} Selected",
      "button_label": "Batch Actions",
      "default_confirmation": "Are you sure you want to do this?",
      "delete_confirmation": "Are you sure you want to delete these %{plural_model}?",
      "labels": {
        "destroy": "Delete"
      },
      "selection_toggle_explanation": "(Toggle Selection)",
      "succesfully_destroyed": {
        "one": "Successfully destroyed 1 %{model}",
        "other": "Successfully destroyed %{count} %{plural_model}"
      }
    },
    "blank_slate": {
      "content": "There are no %{resource_name} yet.",
      "link": "Create one"
    },
    "cancel": "Cancel",
    "comments": {
      "add": "Add Comment",
      "author": "Author",
      "author_missing": "Anonymous",
      "author_type": "Author Type",
      "body": "Body",
      "created_at": "Created",
      "delete": "Delete Comment",
      "delete_confirmation": "Are you sure you want to delete these comment?",
      "errors": {
        "empty_text": "Comment wasn't saved, text was empty."
      },
      "no_comments_yet": "No comments yet.",
      "resource": "Resource",
      "resource_type": "Resource Type",
      "title_content": "Comments (%{count})"
    },
    "create_another": "Create another %{model}",
    "dashboard": "Dashboard",
    "dashboard_welcome": {
      "call_to_action": "To add dashboard sections, checkout 'app/admin/dashboard.rb'",
      "welcome": "Welcome to Active Admin. This is the default dashboard page."
    },
    "delete": "Delete",
    "delete_confirmation": "Are you sure you want to delete this?",
    "delete_model": "Delete %{model}",
    "details": "%{model} Details",
    "devise": {
      "change_password": {
        "submit": "Change my password",
        "title": "Change your password"
      },
      "email": {
        "title": "Email"
      },
      "links": {
        "forgot_your_password": "Forgot your password?",
        "resend_confirmation_instructions": "Resend confirmation instructions",
        "resend_unlock_instructions": "Resend unlock instructions",
        "sign_in": "Sign in",
        "sign_in_with_omniauth_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "login": {
        "remember_me": "Remember me",
        "submit": "Login",
        "title": "Login"
      },
      "password": {
        "title": "Password"
      },
      "password_confirmation": {
        "title": "Confirm Password"
      },
      "resend_confirmation_instructions": {
        "submit": "Resend confirmation instructions",
        "title": "Resend confirmation instructions"
      },
      "reset_password": {
        "submit": "Reset My Password",
        "title": "Forgot your password?"
      },
      "sign_up": {
        "submit": "Sign up",
        "title": "Sign up"
      },
      "subdomain": {
        "title": "Subdomain"
      },
      "unlock": {
        "submit": "Resend unlock instructions",
        "title": "Resend unlock instructions"
      },
      "username": {
        "title": "Username"
      }
    },
    "download": "Download:",
    "dropdown_actions": {
      "button_label": "Actions"
    },
    "edit": "Edit",
    "edit_model": "Edit %{model}",
    "empty": "Empty",
    "filters": {
      "buttons": {
        "clear": "Clear Filters",
        "filter": "Filter"
      },
      "predicates": {
        "contains": "Contains",
        "ends_with": "Ends with",
        "equals": "Equals",
        "from": "From",
        "greater_than": "Greater than",
        "gteq_datetime": "Greater or equal to",
        "less_than": "Less than",
        "lteq_datetime": "Lesser or equal to",
        "starts_with": "Starts with",
        "to": "To"
      }
    },
    "has_many_delete": "Delete",
    "has_many_new": "Add New %{model}",
    "has_many_remove": "Remove",
    "hint": {
      "checkout_email": "short codes: {{payment_plan_title}} {{first_payment_amount}}"
    },
    "index_list": {
      "block": "List",
      "blog": "Blog",
      "grid": "Grid",
      "table": "Table"
    },
    "logout": "Logout",
    "main_content": "Please implement %{model}#main_content to display content.",
    "menu": {
      "settings": "Site"
    },
    "move": "Move",
    "new": {
      "activity_level_options": {
        "option_1": "Couch potato",
        "option_2": "I go for a walk occasionally",
        "option_3": "I work out regularly",
        "option_4": "I live in the gym"
      },
      "button_end": "See my results now",
      "button_next": "Continue",
      "button_start": "Start the quiz",
      "checkbox_accept_send_emails": "By checking this, you agree to get future information from us.",
      "error_cm": "Wrong height",
      "error_email": "Wrong email",
      "error_height_ft": "Wrong feet number",
      "error_height_in": "Inches is required",
      "error_target_weight_kg": "Wrong weight in Kg",
      "error_target_weight_lb": "Wrong weight in lb",
      "error_weight_kg": "Wrong weight in Kg",
      "error_weight_lb": "Wrong weight in lb",
      "error_years": "Wrong years number",
      "errors": "The meal plan has some mistakes, check it out please",
      "familiarity_level_options": {
        "option_1": "Complete beginner",
        "option_2": "Somewhat familiar",
        "option_3": "Practically an expert"
      },
      "introduction_description_1": "Getting the optimal breakdown of Carbs, Protein, and Fat needed for your body type is critical for a successful keto diet plan.",
      "introduction_description_2": "Everyone likes eating different foods. You' ll have the opportunity to chose what ingredients you would like to be included and excluded from your meal plan.",
      "introduction_description_3": "Our meal plans are prepared by nutrition experts, and are specifically tailored to you. We offer a 100% satisfaction, money-back guarantee!",
      "introduction_list_title_1": "Macronutrients calculation",
      "introduction_list_title_2": "Defining your favorite ingredients",
      "introduction_list_title_3": "A meal plan that works",
      "introduction_subtitle": "Congratulations! You are 30 seconds away from your getting your personalized keto compatibility results! This quick quiz will also help us fine-tune and tailor your Simple Keto Diet specifically for your needs!",
      "label_activity_level": "How active are you?",
      "label_activity_level_subtitle": "Select an option that applies to you",
      "label_age": "Your age",
      "label_allowed_foods_allergens": "See something you don' t like? Click on it to remove it.",
      "label_allowed_foods_allergens_subtitle": "Click to remove items you don't want",
      "label_allowed_foods_meats": "Which meats would you like to be included in your meal plan?",
      "label_allowed_foods_meats_subtitle": "Click to remove items you don't want",
      "label_allowed_foods_vegetables": "Now click to remove vegetables you don't want, if any.",
      "label_allowed_foods_vegetables_subtitle": "Click to remove items you don't want",
      "label_cm": "cm",
      "label_email": "Enter your email to learn how to Lose Weight with Simple Keto",
      "label_familiarity": "How familiar are you with the Keto diet?",
      "label_familiarity_level_subtitle": "Select an option that applies to you",
      "label_gender": "Gender",
      "label_height": "Height",
      "label_height_cm": "Height cm",
      "label_height_ft": "feet",
      "label_height_in": "inches",
      "label_introduction": "Tailored Meal Plan Quiz",
      "label_loading": "",
      "label_preferred_activity": "How active are you?",
      "label_target_weight": "Goal Weight",
      "label_target_weight_kg": "Kg",
      "label_target_weight_lb": "lb",
      "label_vegetarian": "vegetarian",
      "label_weight": "Current Weight",
      "label_weight_kg": "kg",
      "label_weight_lb": "lb",
      "label_years": "years",
      "loader_step_1": "Identifying dietary profile",
      "loader_step_2": "Analyzing root cause factors",
      "loader_step_3": "Foods to help eliminate fatigue",
      "loader_step_4": "Foods for metabolism repair",
      "loader_step_5": "Foods that increase energy",
      "measurement_systems": {
        "imperial": "Imperial",
        "metric": "Metric"
      },
      "privacy_description": "We respect your privacy. We will never sell, rent or share your email address. That's more than a policy, it's our personal guarantee!",
      "title_measurements": "Great! Now enter your measurements & age",
      "vegetarian": "Vegetarian"
    },
    "new_model": "New %{model}",
    "next": "Next",
    "pagination": {
      "empty": "No %{model} found",
      "entry": {
        "one": "entry",
        "other": "entries"
      },
      "multiple": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{total}</b> in total",
      "multiple_without_total": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b>",
      "one": "Displaying <b>1</b> %{model}",
      "one_page": "Displaying <b>all %{n}</b> %{model}",
      "per_page": "Per page: "
    },
    "powered_by": "Powered by %{active_admin} %{version}",
    "previous": "Previous",
    "scopes": {
      "all": "All"
    },
    "search_status": {
      "current_filters": "Current filters:",
      "current_scope": "Scope:",
      "headline": "Search status:",
      "no_current_filters": "None"
    },
    "sidebars": {
      "filters": "Filters",
      "search_status": "Search Status"
    },
    "status_tag": {
      "no": "No",
      "unset": "No",
      "yes": "Yes"
    },
    "unsupported_browser": {
      "headline": "Please note that ActiveAdmin no longer supports Internet Explorer versions 8 or less.",
      "recommendation": "We recommend that you <a href=\"http://browsehappy.com/\">upgrade your browser</a> to improve your experience.",
      "turn_off_compatibility_view": "If you are using IE 9 or later, make sure you <a href=\"https://support.microsoft.com/en-us/help/17471\">turn off \"Compatibility View\"</a>."
    },
    "view": "View"
  },
  "activerecord": {
    "attributes": {
      "active_admin/comment": {
        "author_type": "Author type",
        "body": "Body",
        "created_at": "Created",
        "namespace": "Namespace",
        "resource_type": "Resource type",
        "updated_at": "Updated"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      },
      "models": {
        "meal_plan": {
          "attributes": {
            "banned_foods": {
              "invalid": "[%{elements}] Includes invalid foods"
            }
          }
        },
        "recipe": {
          "attributes": {
            "foods": {
              "invalid": "[%{elements}] Includes invalid values"
            },
            "meal_type": {
              "invalid": "[%{elements}] Includes invalid values"
            }
          }
        }
      }
    },
    "models": {
      "active_admin/comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "recipe/as_admin": "Recipe"
    }
  },
  "admin": {
    "settings": {
      "form": {
        "default_checkout_form": "Default Checkout form",
        "title": "Site Settings"
      },
      "update": {
        "ok": "App Settings updated!"
      }
    }
  },
  "checkouts": {
    "2_plans_2_pages": {
      "form": {
        "label_card_name": "Card holder name",
        "label_card_number": "Card number",
        "label_cvv": "CVV code",
        "label_exp_date": "Expiration date",
        "placeholder_card_name": "E.g. John Henderson",
        "placeholder_cvv": "CVV",
        "submit": "Get Keto Plan",
        "submit_wait": "Processing..."
      },
      "prices": {
        "continue": "Continue",
        "features_list_col_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>\n<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">24/7 nutritionist support</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
        "features_list_title_html": "All plans include",
        "subtitle": "Select your plan",
        "title": "Get your personal Keto Diet"
      }
    },
    "2_plans_with_form": {
      "card": {
        "label_card_name": "Card holder name",
        "label_card_number": "Card number",
        "label_cvv": "CVV/CVC",
        "label_email": "Email (where you’ll receive your meal plan)",
        "label_exp_date": "Card Expiry",
        "placeholder_card_name": "e.g. John Doe",
        "placeholder_cvv": "CVV",
        "placeholder_email": "e.g johndoe@simpleketo.com",
        "submit": "Get My Keto Plan",
        "submit_wait": "Processing..."
      },
      "paypal_checkout_fields": {
        "confirm": "Confirm",
        "submit_wait": "Confirm..."
      },
      "template": {
        "continue": "Continue",
        "features_list_col_1_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">FREE BONUS: Keto for beginners e-book</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>",
        "features_list_col_2_html": "<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">24/7 nutritionist support</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
        "features_list_title_html": "All plans <br>include:",
        "secure": "Secure Checkout",
        "subtitle": "Select your plan",
        "title": "Get your personal Keto Diet"
      }
    },
    "2_plans_with_modal": {
      "form": {
        "label_card_name": "Card holder name",
        "label_card_number": "Card number",
        "label_cvv": "CVV code",
        "label_exp_date": "Expiration date",
        "placeholder_card_name": "E.g. John Henderson",
        "placeholder_cvv": "CVV",
        "submit": "ORDER MEAL PLAN",
        "submit_wait": "Processing..."
      },
      "template": {
        "continue": "Continue",
        "features_list_col_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>\n<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">24/7 nutritionist support</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
        "features_list_title_html": "All plans include",
        "subtitle": "Select your plan",
        "title": "Get your personal Keto Diet"
      }
    },
    "3_plans_with_form": {
      "card": {
        "label_card_name": "Card holder name",
        "label_card_number": "Card number",
        "label_cvv": "CVV",
        "label_email": "Email (where you’ll receive your meal plan)",
        "label_exp_date": "Expiry Date",
        "placeholder_card_name": "e.g. John Doe",
        "placeholder_cvv": "CVV",
        "placeholder_email": "e.g johndoe@simpleketo.com",
        "submit": "Get My Keto Plan",
        "submit_wait": "Processing..."
      },
      "paypal_checkout_fields": {
        "confirm": "Confirm",
        "submit_wait": "Confirm..."
      },
      "template": {
        "continue": "Continue",
        "features_list_col_1_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">FREE BONUS: Keto for beginners e-book</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>",
        "features_list_col_2_html": "<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">24/7 nutritionist support</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
        "features_list_title_html": "All plans <br>include:",
        "secure": "Secure Checkout",
        "subtitle": "Select your plan",
        "title": "Get your personal Keto Diet"
      }
    },
    "3_plans_with_modal": {
      "form": {
        "label_card_name": "Card holder name",
        "label_card_number": "Card number",
        "label_cvv": "CVV code",
        "label_exp_date": "Expiration date",
        "placeholder_card_name": "E.g. John Henderson",
        "placeholder_cvv": "CVV",
        "submit": "ORDER MEAL PLAN",
        "submit_wait": "Processing..."
      },
      "pricing_card": {
        "buy_button": "ORDER NOW"
      },
      "template": {
        "button_open_checkout": "Order Now",
        "features_list_col_1": {
          "0": "Professional Keto nutrition analysis",
          "1": "Personalized Simple Keto Diet",
          "2": {
            "FREE BONUS": "Keto for beginners e-book"
          },
          "3": "Easy-to-follow, delicious recipes"
        },
        "features_list_col_1_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>",
        "features_list_col_2_html": "<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
        "features_list_title": "All plans include",
        "secure": "Secure Checkout",
        "subtitle": "All our meal plans are one-time purchases, tailor-made and prepared by our nutrition experts. No subscriptions, no hidden fees, no surprises.",
        "text_special_offer": "Most popular - save 50%",
        "title": "Choose your tailored Simple Keto Diet"
      }
    },
    "countdown": {
      "expires": "Offers expires in:"
    },
    "form": {
      "label_card_name": "Card holder name",
      "label_card_number": "Card number",
      "label_cvv": "CVV code",
      "label_exp_date": "Expiration date",
      "placeholder_card_name": "E.g. John Henderson",
      "placeholder_cvv": "CVV",
      "submit": "ORDER MEAL PLAN",
      "submit_wait": "Processing..."
    },
    "info": {
      "bullets": {
        "eight": "Improved health",
        "five": "Reduced cravings",
        "nine": "Better quality of life",
        "one": "Sustainable weight loss",
        "seven": "Reduced inflammation",
        "six": "Easy, delicious recipes",
        "subtitle_eight": " - reduce inflammation and risk of certain disease",
        "subtitle_nine": " - improved mood and sleep quality",
        "subtitle_one": " - keep weight off",
        "subtitle_six": " - quick prep and cleanup ",
        "subtitle_three": " - eat the foods you love",
        "subtitle_two": " - loose weight quicker",
        "ten": "Improved mood and sleep",
        "three": "Non-restrictive",
        "two": "Faster metabolism"
      },
      "headline": "What you get:"
    },
    "new": {
      "button_open_checkout": "Order Now",
      "features_list_col_1": {
        "0": "Professional Keto nutrition analysis",
        "1": "Personalized Simple Keto Diet",
        "2": {
          "FREE BONUS": "Keto for beginners e-book"
        },
        "3": "Easy-to-follow, delicious recipes"
      },
      "features_list_col_1_html": "<li><span class=\"checkout-features-box__list__title\">Professional Keto nutrition analysis</span></li>\n<li><span class=\"checkout-features-box__list__title\">Personalized Simple Keto Diet</span></li>\n<li><span class=\"checkout-features-box__list__title\">Easy-to-follow, delicious recipes</span></li>",
      "features_list_col_2_html": "<li><span class=\"checkout-features-box__list__title\">Simple ingredients from any store</span></li>\n<li><span class=\"checkout-features-box__list__title\">Digital access on any device</span></li>\n<li><span class=\"checkout-features-box__list__title\">Printable grocery list</span></li>",
      "features_list_title": "All plans include",
      "secure": "Secure Checkout",
      "subtitle": "All our meal plans are one-time purchases, tailor-made and prepared by our nutrition experts. No subscriptions, no hidden fees, no surprises.",
      "text_special_offer": "Most popular - save 50%",
      "title": "Choose your tailored Simple Keto Diet"
    },
    "plans_include": {
      "eight": {
        "subtitle": "If you're unsatisfied with your plan for any reason we'll refund your money.",
        "title": "30-Day Risk-Free Money-Back Guarantee"
      },
      "five": {
        "subtitle": "All meals are Keto friendly and created by a certified nutritionist.",
        "title": "Nutritionist Created Recipes"
      },
      "four": {
        "subtitle": "Our ingredients are affordable and found at any grocery store.",
        "title": "Simple Ingredients"
      },
      "headline": "All Plans Include",
      "one": {
        "subtitle": "Custom-made according to your dietary preferences.",
        "title": "A Personalized Keto Meal Plan"
      },
      "seven": {
        "subtitle": "Make grocery shopping a breeze with a weekly grocery list.",
        "title": "Grocery List"
      },
      "six": {
        "subtitle": "Treat yourself to a snack without losing your progress.",
        "title": "Keto Snacks"
      },
      "three": {
        "subtitle": "Easily substitute any recipe for another one that matches your preferences.",
        "title": "Replace Any Recipe"
      },
      "two": {
        "subtitle": "Simple and quick recipes you can prepare in under 20 minutes.",
        "title": "Easy Recipes"
      }
    },
    "pricing_card": {
      "buy_button": "ORDER NOW"
    },
    "questions": {
      "questions": [
        {
          "title": "What happens after I order?",
          "text": "After you complete your order, our nutritionists review the information you’ve provided and start creating your personalized meal plan. Using your food preferences, medical circumstances and lifestyle habits, we create a plan that’s guaranteed to be a perfect fit for you and your weight loss needs. It will be delivered directly to your email and will include everything you’ll need to start losing weight right away."
        }
      ],
      "title": "People ask"
    },
    "reviews": {
      "slider": [
        {
          "id": 1,
          "author": "Helen Adams",
          "image": "helen.png",
          "age_location": "41, Texas",
          "pounds_lost": "Lost 23 lbs",
          "text": "I love how simple to cook and delicious the recipes are! I stopped feeling hungry and tired all the time, and for the first time I’ve actually followed through with a diet for more than a few weeks. 23lbs in the first 5 weeks and still going strong!"
        },
        {
          "id": 2,
          "author": "Sasha Kent",
          "image": "sasha.png",
          "age_location": "36, New York",
          "pounds_lost": "Lost 12 lbs",
          "text": "With all the diet services out there I gotta say I wasn’t sure this was gonna work, but it turns out that the meal plan is actually tailored to my taste, which makes it much easier to follow. I’ve already lost 12lbs in the first 3 weeks."
        },
        {
          "id": 3,
          "author": "David Briarswood",
          "image": "david.png",
          "age_location": "52, Illinois",
          "pounds_lost": "Lost 26 lbs",
          "text": "Simple Keto was the third diet I tried, but the only one that actually worked and that I could stick to thanks to it being simple to cook and quite tasty. Couldn’t be happier with the results!"
        },
        {
          "id": 4,
          "author": "Sarah Wilhelm",
          "image": "sarah.png",
          "age_location": "49, Ohio",
          "pounds_lost": "Lost 21 lbs",
          "text": "I finally found a meal plan that’s not a pain to follow! The results in the past 5 weeks have been amazing - I’ve lost 21lbs! Really pleased with how easy to use the platform is."
        }
      ],
      "title": "People love our meal plans",
      "title_mobile": "People love us",
      "verified_buyer": "Verified buyer"
    },
    "sample_recipes": {
      "headline": "Sample Recipes",
      "slider": [
        {
          "id": 1,
          "image": "frittata.jpg",
          "name": "Bacon Asparagus Frittata",
          "type": "Breakfast",
          "macros": "9g Carbs ● 12g Protein ● 14g Fats"
        },
        {
          "id": 2,
          "image": "pancakes.jpg",
          "name": "Peanut Butter Pancakes",
          "type": "Breakfast",
          "macros": "10g Carbs ● 14g Protein ● 34g Fats"
        },
        {
          "id": 3,
          "image": "taco-salad.jpg",
          "name": "Taco Salad",
          "type": "Lunch",
          "macros": "14g Carbs ● 33g Protein ● 33g Fats"
        },
        {
          "id": 4,
          "image": "butter-salmon.jpg",
          "name": "Butter Salmon and Veggies",
          "type": "Lunch",
          "macros": "9g Carbs ● 22g Protein ● 12g Fats"
        },
        {
          "id": 5,
          "image": "pb-chips.jpg",
          "name": "Peanut Butter Chocolate Chip Bites",
          "type": "Snack",
          "macros": "5g Carbs ● 4g Protein ● 17g Fats"
        },
        {
          "id": 6,
          "image": "blueberry-smoothie.jpg",
          "name": "Blueberry Smoothie",
          "type": "Snack",
          "macros": "16g Carbs ● 27g Protein ● 23g Fats"
        },
        {
          "id": 7,
          "image": "butter-chicken.jpg",
          "name": "Butter Chicken",
          "type": "Dinner",
          "macros": "17g Carbs ● 28g Protein ● 39g Fats"
        },
        {
          "id": 8,
          "image": "tzatziki-lamb-burgers.jpg",
          "name": "Tzatziki Lamb Burgers",
          "type": "Dinner",
          "macros": "23g Carbs ● 36g Protein ● 40g Fats"
        }
      ]
    },
    "stories": {
      "after": "After",
      "before": "Before",
      "slider": [
        {
          "title": "Brie, -17 lbs",
          "text": "“This is the first time that dieting wasn’t painful. Meals are super tasty! Recipes are easy to follow and quick to cook. I don’t feel hungry after my meals. Best part, I lost 17lbs in the 4 weeks!”"
        },
        {
          "title": "Ross, -32 lbs",
          "text": "“Honestly I was skeptical at first, but after loosing 15lbs in a month I’m loving keto. Results came fast and I was able to loose over 32 lbs in total.  Best part, I’ve been able to keep the weight off for months now, unlike previous diet attempts.”"
        },
        {
          "title": "Sarah, -38 lbs",
          "text": "“I’ve always struggled with keeping diets, now I realize the main reason was I didn’t know what to cook. The meal planning is automatic and the recipes are easy to follow. Simpleketo really makes dieting easy and fun! I noticed a difference in my body after 2 weeks.”"
        }
      ],
      "success_stories": "Success Stories"
    },
    "what_you_get": {
      "headline": "What you get",
      "one": {
        "subtitle": "Get a fully custom Keto meal plan based on your dietary preferences, covering Breakfast, Lunch, Dinner and Snacks. Avoid the hassle of tracking nutrients, while boosting your results by following the plan created specifically for your needs.",
        "title": "Personalized Keto Meal Plan"
      },
      "three": {
        "subtitle": "Every week you'll get a shopping list with all the ingredients you'll need for the week's recipes. Make grocery shopping a breeze with your weekly shopping list.",
        "title": "Weekly Shopping List"
      },
      "two": {
        "subtitle": "No need to spend hours in the kitchen or invest in fancy cooking equipment. Your Keto plan is based entirely on simple and quick recipes that will help you achieve the results you need, with no complications.",
        "title": "Simple and Quick Recipes"
      }
    }
  },
  "commands": {
    "charges": {
      "create_command": {
        "errors": {
          "form_validation": "Please review the errors..",
          "tpv_generic": "Transaction Failed. Please check all fields are correct or try again with a different card."
        }
      }
    },
    "users_payment_plans": {
      "create_command": {
        "errors": {
          "form_validation": "Please review the errors.",
          "tpv_generic": "Payment processing error, please check card details are correct or try using a different card"
        }
      }
    }
  },
  "company_address": "60, Mayor Tompson Str, Building 12, level 1, 1407 Sofia, Bulgaria",
  "company_info": "Company no 205083653, VAT EU BG205083653",
  "confirms": {
    "show": {
      "expedited": "We're expediting the creation of your meal plan, it will be ready in 1 hour.",
      "meal_ready": "Once your meal plan is ready we’ll email you with a link to access it. You will be able to login with the password below.",
      "next_button": "Show my keto meal plan",
      "oder_includes": "Your order also includes:",
      "password": "Your password is: %{password}",
      "title": "Thank you for ordering your personalized SimpleKeto Meal Plan.",
      "up_to_24_hours": "Creating your meal plan can take up to 24 hours, we’ll do our best to have it ready asap.",
      "we_received": "We’ve received your order and our team of nutritionists will be reviewing your profile and creating a custom meal plan to meet your needs."
    }
  },
  "copy_right": "©%{year} Novomedia EOOD. All rights are reserved",
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d",
      "table_short": "%b %d %Y"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "enums": {
    "recipe": {
      "foods": {
        "alcohol": "Alcohol",
        "asparagus": "Asparagus",
        "avocado": "Avocado",
        "beans": "Beans",
        "beef": "Beef",
        "breakfast": "Breakfast",
        "broccoli": "Broccoli",
        "butter": "Butter",
        "carbonated": "Carbonated beverages",
        "cauliflower": "Cauliflower",
        "cheese": "Cheese",
        "chicken": "Chicken",
        "coconut": "Coconut",
        "coffee": "Coffee",
        "dinner": "Dinner",
        "egg": "Egg",
        "eggplant": "Eggplant",
        "fish": "Fish",
        "gluten": "Gluten",
        "hummus": "Hummus",
        "lamb": "Lamb",
        "lunch": "Lunch",
        "milk": "Milk",
        "mushrooms": "Mushrooms",
        "none": "None of the above",
        "nuts": "Nuts",
        "olives": "Olives",
        "onions": "Onions",
        "peas": "Peas",
        "pepper": "Pepper",
        "pork": "Pork",
        "shellfish": "Shellfish",
        "snack": "Snack",
        "spinach": "Spinach",
        "sweet_potato": "Sweet potato",
        "sweets": "Sweet treats",
        "tofu": "Tofu",
        "tomatoes": "Tomatoes",
        "vegetarian": "Plant-based",
        "zucchini": "Zucchini"
      },
      "meats": {
        "beef": "Beef",
        "chicken": "Chicken",
        "fish": "Fish",
        "lamb": "Lamb",
        "pork": "Pork",
        "vegetarian": "Plant-based"
      }
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "footer": {
    "about_us": "About us",
    "blog": "Blog",
    "contact_us": "Contact us",
    "disclaimer": "Disclaimer",
    "eat_enjoy": "Eat the food you love and enjoy your life.",
    "home": "Home",
    "how_it_works": "How it works",
    "login": "Login",
    "pricing": "Pricing",
    "privacy_policy": "Privacy policy",
    "terms_of_service": "Terms of Service"
  },
  "forms": {
    "checkout_form": {
      "errors": {
        "email_exists": "is in use, please login to continue.",
        "future_cc_date": "Credit card has expired.",
        "wrong_cc_date": "The date is incorrect.",
        "wrong_cc_number": "Please check card number and try again."
      }
    }
  },
  "grocery": {
    "print": "Print Grocery List",
    "this_week": "This Week's Grocery List",
    "view": "View"
  },
  "helpers": {
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}",
          "zero": "No %{entry_name} found"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "home": {
    "index": {
      "female": "WOMAN",
      "get_your_personailzed": "Get your personalized",
      "keto_meal_plan": "keto meal plan",
      "label_gender": "Select your gender:",
      "login": "Login",
      "male": "MAN",
      "subtitle": "Simple and delicious meals to lose weight",
      "subtitle_desktop": "Find out with our BMI and age adjusted quiz",
      "subtitle_mobile": "Take our free 30-second quiz",
      "take_quiz": "Take the free quiz below",
      "title_html": "Get  your personalized<br> <span>Keto Meal Plan</span>",
      "title_mobile": "Get your personalized Keto Meal Plan"
    }
  },
  "landing_pages": {
    "28days": {
      "audience": "for 28 days",
      "subtitle": "Join the 28-day Keto Challenge and start losing weight today"
    },
    "beginners": {
      "audience": "for beginners"
    },
    "improve_health": "Improve your health and extend your lifespan",
    "men": {
      "audience": "for men",
      "boost_weight": "Boost your weight loss efforts with a personalized meal plan"
    },
    "over40": {
      "audience": "for people over 40",
      "improve_health": "Improve your health with our delicious keto recipes"
    },
    "simple_and_delicious": "Simple and delicious meals to lose weight",
    "women": {
      "audience": "for women",
      "get_in_shape": "Get in better shape with a custom-made keto diet"
    }
  },
  "layouts": {
    "html_head": {
      "title": "Simple Keto Diet"
    }
  },
  "meal_plans": {
    "new": {
      "activity_level_options": {
        "option_1": "Never",
        "option_2": "I go for a walk occasionally",
        "option_3": "1-2 times a week",
        "option_4": "3-5 times a week",
        "option_5": "5-7 times a week"
      },
      "activity_level_options_short": {
        "option_1": "Rarely",
        "option_2": "I go for a walk occasionally",
        "option_3": "1-2 times a week",
        "option_4": "3-5 times a week",
        "option_5": "5-7 times a week"
      },
      "bedtime_options": {
        "option_no": "Not really",
        "option_yes": "Yes, mostly"
      },
      "body_type_options": {
        "option_ectomorph": "Ectomorph",
        "option_endomorph": "Endomorph",
        "option_mesomorph": "Mesomorph",
        "option_unsure": "I'm not sure"
      },
      "button_end": "See my results now",
      "button_next": "Continue",
      "button_start": "Start the quiz",
      "checkbox_accept_send_emails": "By checking this, you agree to get future information from us.",
      "conditions_options": {
        "option_anxiety": "Anxious",
        "option_cholesterol": "Unhealthy Cholesterol",
        "option_depression": "Mood Swings",
        "option_diabetes": "Erratic Blood Sugar",
        "option_heart": "High Cardiovascular Risk",
        "option_kidney": "Low Kidney Function",
        "option_liver": "Elevated Liver Enzymes",
        "option_migraines": "Frequent Headaches",
        "option_none": "None of the above",
        "option_pressure": "Erratic Blood Pressure",
        "option_surgery": "Recovering From Surgery",
        "option_thyroid": "Thyroid Issues",
        "option_unsaid": "Rather not say"
      },
      "continue": "Continue",
      "disease_options": {
        "option_no": "No",
        "option_unsure": "Not sure",
        "option_yes": "Yes"
      },
      "energy_level_options": {
        "option_1": "Stable",
        "option_2": "Erratic",
        "option_3": "I am tired all day",
        "option_4": "I feel tired after meals",
        "option_5": "I feel tired in the morning"
      },
      "error_email": "Wrong email",
      "error_goal_max_weight_kgs": "Maximum weight is 300kg.",
      "error_goal_max_weight_lbs": "Maximum weight is 800lbs.",
      "error_goal_min_weight_kgs": "Minimum weight is 40kg.",
      "error_goal_min_weight_lbs": "Minimum weight is 80lbs.",
      "error_invalid_goal_weight": "Your desired weight can't be higher than your current weight.",
      "error_invalid_weight": "Please, input valid weight",
      "errors": "The meal plan has some mistakes, check it out please",
      "familiarity_level_options": {
        "option_1": "Complete beginner",
        "option_2": "Somewhat familiar",
        "option_3": "Practically an expert"
      },
      "goals_options": {
        "option_both": "Both",
        "option_fit": "Get fit",
        "option_weight": "Lose weight"
      },
      "ideal_weight_options": {
        "option_1": "Yes, less than a year ago",
        "option_2": "1-3 years ago",
        "option_3": "More than 3 years ago",
        "option_4": "No, never"
      },
      "introduction_description_1": "Getting the optimal breakdown of Carbs, Protein, and Fat needed for your body type is critical for a successful keto diet plan.",
      "introduction_description_2": "Everyone likes eating different foods. You' ll have the opportunity to chose what ingredients you would like to be included and excluded from your meal plan.",
      "introduction_description_3": "Our meal plans are prepared by nutrition experts, and are specifically tailored to you. We offer a 100% satisfaction, money-back guarantee!",
      "introduction_list_title_1": "Macronutrients calculation",
      "introduction_list_title_2": "Defining your favorite ingredients",
      "introduction_list_title_3": "A meal plan that works",
      "introduction_subtitle": "Congratulations! You are 30 seconds away from your getting your personalized keto compatibility results! This quick quiz will also help us fine-tune and tailor your Simple Keto Diet specifically for your needs!",
      "label_achieved_weight": "Have you ever achieved your ideal weight?",
      "label_activity_level": "How often do you exercise?",
      "label_activity_level_subtitle": "Select an option that applies to you",
      "label_age_height": "Got it, what is your age & height?",
      "label_allergens": "Do you want to include any of the following?",
      "label_allowed_foods_allergens": "Do you want to include any of the following?",
      "label_allowed_foods_allergens_subtitle": "Click to remove items you don't want",
      "label_allowed_foods_consumptions": "Do you regularly consume any of the following?",
      "label_allowed_foods_meats": "Which meats would you like to be included in your meal plan?",
      "label_allowed_foods_meats_subtitle": "Click to remove items you don't want",
      "label_allowed_foods_others": "Any other products you would like to include in your meal plan?",
      "label_allowed_foods_vegetables": "Select the vegetables you want to include",
      "label_allowed_foods_vegetables_subtitle": "Click to remove items you don't want",
      "label_bed_time": "Do you go to bed at roughly the same time each day?",
      "label_bedtime": "Do you go to bed at roughly the same time each day?",
      "label_body_type": "Ok, how would you describe your body type?",
      "label_conditions": "Does any of the following apply to you?",
      "label_consume": "Do you regularly consume any of the following?",
      "label_current_weight": "What is your current weight?",
      "label_desired": "desired",
      "label_desired_weight": "What is your desired weight?",
      "label_email": "We've created a custom Keto meal plan that will help you reach your desired weight.",
      "label_email_disclaimer": "We value your privacy and will never share or sell your email or personal information with any 3rd party. Your data is SSL encrypted.",
      "label_energy": "How would you describe your energy levels throughout the day?",
      "label_energy_level": "How would you describe your energy levels throughout the day?",
      "label_enter_email": "Enter your email to receive your plan",
      "label_exercise": "How active are you?",
      "label_exercise_short": "How often do you exercise?",
      "label_familiarity": "How familiar are you with the Keto diet?",
      "label_familiarity_level_subtitle": "Select an option that applies to you",
      "label_gender": "Gender",
      "label_goal": "What is your primary goal?",
      "label_goal_weight": "Ok, now what is your ",
      "label_ideal_weight": "Have you ever achieved your ideal weight?",
      "label_input_current_weight": "Current Weight",
      "label_input_desired_weight": "Weight",
      "label_introduction": "Tailored Meal Plan Quiz",
      "label_knowledge": "How familiar are you with the Keto diet?",
      "label_loading": "",
      "label_meal_prep": "How much time would you like to spend preparing meals?",
      "label_measurements": "Got it, what is your age & height?",
      "label_meats": "Which meats would you like to be included in your meal plan?",
      "label_meats_short": "Which proteins would you like to include in your meal plan?",
      "label_medical": "Does any of the following apply to you?",
      "label_preferred_activity": "How active are you?",
      "label_preferred_measurement_system": "What is your preferred measurement system?",
      "label_prep_time": "How much time would you like to spend preparing meals?",
      "label_products": "Any other products you would like to include in your meal plan?",
      "label_products_short": "Any other ingredients you would like to include in your meal plan?",
      "label_results": "Get a Keto Plan that forces weight loss",
      "label_sleep": "How much sleep do you get?",
      "label_smoker": "Are you an active smoker?",
      "label_stomach": "Do you have stomach ulcers/diseases?",
      "label_stomach_diseases": "Do you have stomach ulcers/diseases?",
      "label_vegetables": "Select the vegetables you want to include",
      "label_vegetarian": "vegetarian",
      "label_water": "How much water do you drink daily?",
      "label_weight": "weight?",
      "loader_step_1": "Identifying dietary profile",
      "loader_step_2": "Analyzing root cause factors",
      "loader_step_3": "Foods to help eliminate fatigue",
      "loader_step_4": "Foods for metabolism repair",
      "loader_step_5": "Foods that increase energy",
      "meal_prep_options": {
        "option_1": "30 minutes or less",
        "option_2": "30-60 minutes",
        "option_3": "More than 1 hour"
      },
      "meal_prep_options_short": {
        "option_1": "Less than 20 minutes",
        "option_2": "Up to 30 minutes",
        "option_3": "More than 30 minutes"
      },
      "measurement_systems": {
        "height": {
          "cms": "Metric",
          "in": "Imperial"
        },
        "weight": {
          "kg": "KG",
          "lbs": "LBS"
        }
      },
      "privacy_description": "We respect your privacy. We will never sell, rent or share your email address. That's more than a policy, it's our personal guarantee!",
      "select_option": "Please select an option",
      "select_three_items": "Please select at least 3 options.",
      "sleep_options": {
        "option_1": "Less than 5 hours",
        "option_2": "5-6 hours",
        "option_3": "7-8 hours",
        "option_4": "8+ hours"
      },
      "smoker_options": {
        "option_no": "No",
        "option_yes": "Yes"
      },
      "title_age_height": "Great! Now enter your measurements & age",
      "title_measurements": "Great! Now enter your measurements & age",
      "use_this_email": "Use this Email",
      "vegetarian": "Vegetarian",
      "water_options": {
        "option_1": "None",
        "option_2": "Less than 2 glasses",
        "option_3": "2-6 glasses",
        "option_4": "7+ glasses"
      }
    },
    "steps": {
      "measurements": {
        "error_age": "Please, input valid age.",
        "error_cm": "Please, input valid height.",
        "error_height_ft": "Wrong feet number",
        "error_height_in": "Inches is required",
        "error_min_in": "Minimum height is 3 feet and 4 inches.",
        "error_target_weight_kg": "Wrong weight in Kg",
        "error_target_weight_lb": "Wrong weight in lb",
        "error_weight_kg": "Wrong weight in Kg",
        "error_weight_lb": "Wrong weight in lb",
        "error_years": "Wrong years number",
        "label_age": "Your age",
        "label_cm": "cm",
        "label_height": "Height",
        "label_height_cm": "Height (cm)",
        "label_height_ft": "Height (feet)",
        "label_height_in": "Height (inches)",
        "label_target_weight": "Goal Weight",
        "label_target_weight_kg": "Kg",
        "label_target_weight_lb": "lb",
        "label_weight": "Current Weight",
        "label_weight_kg": "kg",
        "label_weight_lb": "lb",
        "label_years": "years"
      }
    }
  },
  "meals": {
    "update": {
      "ko": "Something went wrong with replacing this recipe",
      "ok": "Recipe replaced successfully!"
    }
  },
  "metrics": {
    "index": {
      "achievable_weight": {
        "text": "We have determined this by looking at your activity level, metabolic health, and fatigue score.",
        "title": "Achievable Weight"
      },
      "button": "GET MY KETO PLAN",
      "carbohydrates": "Carbohydrates",
      "fat": "Fat",
      "first_week": "After first week",
      "graphic": {
        "text": "Of people with similar profiles lost weight using Simple Keto Diet."
      },
      "metabolic": {
        "actual_age": "Actual age",
        "metabolic_age": "Metabolic age",
        "text": "The metabolic age indicates the age of your body. If your metabolic age is higher than your actual age, it could mean you’re experiencing health problems or may need to change your eating and exercising habits.",
        "title": "Metabolic Age"
      },
      "press": "Keto Diet was covered in:",
      "promise": {
        "expert": "Loredana Condoiu",
        "expert_title": "Certified Nutrition Expert and Fitness Coach",
        "text_html": "<p>We believe in a balanced approach to weight loss and, more importantly, a sustainable way to keep that weight off. We know that most diet and weight loss programs don’t work for one simple reason - they’re just too hard to follow. Too restrictive, too difficult to cook for, ingredients that are too expensive or hard to find.</p><p>Don’t blame yourself if your past weight loss attempts didn’t work. It’s not you, it’s the plan. That’s why our nutritionists work tirelessly to prepare a personalized plan custom fit to your needs. We want to make sure it seamlessly becomes a part of your daily life. The best diet plan is one you can follow.</p><p>Try it out and we guarantee you will be satisfied.</p>",
        "title": "Our promise"
      },
      "protein": "Protein",
      "subtitle": "Based on your answers you will weigh...",
      "summary": {
        "bmi": "BMI",
        "results": {
          "normal": "Normal",
          "obese": "Obese",
          "overweight": "Overweight",
          "underweight": "Underweight"
        },
        "subtitle": "You are likely",
        "title": "Personal summary"
      },
      "title_html": "Get a Keto Plan that forces weight loss",
      "total_calories": "Total"
    }
  },
  "models": {
    "recipe": {
      "difficulty": {
        "complicated": "Complicated",
        "easy": "Easy",
        "medium": "Medium",
        "unknown": "Unknown"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "passwords": {
    "create": {
      "not_found": "The e-mail address does not exist"
    },
    "edit": {
      "new_password": "New password",
      "no_token": "No token provided",
      "submit": "Update my password",
      "title": "Reset Password"
    },
    "new": {
      "back": "Back to Log In",
      "submit": "Reset my password",
      "subtitle": "Enter your address and we will send you a password reset link.",
      "title": "Reset Password"
    },
    "reset_email_sent": {
      "click_here": "Click here to resend",
      "subtitle": "We've sent you an email to the address you provided. Please click the link we've sent to reset your password. Didn't receive an email?",
      "title": "Please verify your email"
    },
    "update": {
      "ko": "Invalid token",
      "ok": "Your password has been reset successfully. You have been signed in."
    }
  },
  "payment_mailer": {
    "checkout": {
      "body_html": "<p>Congratulations! Your journey towards a healthier, leaner life has begun!</p>\n<p>We’ve received your order and our team of nutritionists will be reviewing your profile and creating a custom meal plan to meet your needs. This can take up to 2-3 hours, we’ll do our best to have it ready asap. </p>\n<p>Once your meal plan is ready we’ll email you with a link to access it. You will be able to login with the password below.</p>\n<p><b>Your password is:</b> %{default_password} </p>\n<br>\n<p>Order details:</p>\n<p>Meal Plan: %{plan_title}</p>\n<p>Order total: %{amount}</p>\n<p>Payment method: Credit card</p>\n<p>Payment will appear on your bank statement as: <a href=%{host}>%{host}</a></p>\n<br>\n<p>We are excited for you and your soon to emerge progress! If you have any questions please do not hesitate to contact us at <a href=\"mailto:support@simpleketodiet.com\">support@simpleketodiet.com</a>. We're happy to help!</p>\n<p>Best,</p>\n<p>SimpleKeto Team</p>",
      "body_txt": "Congratulations!\n\nYour journey for a healthier, leaner life has just started!\n\nHere are your order details:\n\nOrder total: %{amount}\n\nPayment method: Credit card\n\nWhat' s next?\n\nOur professional nutrition experts are now checking your nutrition profile to make sure you receive the most suitable plan tailored for your specific needs.\n\nThis can take up to 1 business day so please bear with us. We'll do our best to have it done as quick as possible.\n\nAs soon as your meal plan is ready, we will immediately send an email with a link to access it.\n\nWe are excited to welcome you on board! If you have any other questions please do not hesitate to contact us. We're happy to help!",
      "default_password_block_html": "<p><b>Your password is:</b> %{default_password} </p>",
      "subject": "Your SimpleKeto order has been received"
    },
    "remind": {
      "body_html": "<p>Hello,</p>\n<p>Your meal plan is waiting for you. Click <a href=\"%{url}\">here</a> to complete the payment</p>\n<p>Regards</p>",
      "body_txt": "Hello,\nYour meal plan is waiting for you. Click here: %{url} to complete the payment.\nRegards",
      "subject": "KetoPlan"
    },
    "unsubscribe": {
      "email_body_html": "<p>Dear %{name}</p>\n<p>We are sorry to see you go.</p>\n<p>Your subscription has been successfully canceled. You will no longer be charged for our services.</p>\n<p>We wish you the best of luck with your future endeavors.</p>\n<p>Respectfully,</p>\n<p><a href='%{root_url}'>Simple Keto Diet</a></p>",
      "subject": "Your subscription has been cancelled"
    }
  },
  "paypal": {
    "errors": {
      "payment_method_not_found": "Couldn't find a valid PayPal payment method",
      "transaction_failed": "PayPal transaction declined",
      "upsell_plans_empty": "No upsell plans selected"
    }
  },
  "ransack": {
    "all": "all",
    "and": "and",
    "any": "any",
    "asc": "ascending",
    "attribute": "attribute",
    "combinator": "combinator",
    "condition": "condition",
    "desc": "descending",
    "or": "or",
    "predicate": "predicate",
    "predicates": {
      "blank": "is blank",
      "cont": "contains",
      "cont_all": "contains all",
      "cont_any": "contains any",
      "does_not_match": "doesn't match",
      "does_not_match_all": "doesn't match all",
      "does_not_match_any": "doesn't match any",
      "end": "ends with",
      "end_all": "ends with all",
      "end_any": "ends with any",
      "eq": "equals",
      "eq_all": "equals all",
      "eq_any": "equals any",
      "false": "is false",
      "gt": "greater than",
      "gt_all": "greater than all",
      "gt_any": "greater than any",
      "gteq": "greater than or equal to",
      "gteq_all": "greater than or equal to all",
      "gteq_any": "greater than or equal to any",
      "in": "in",
      "in_all": "in all",
      "in_any": "in any",
      "lt": "less than",
      "lt_all": "less than all",
      "lt_any": "less than any",
      "lteq": "less than or equal to",
      "lteq_all": "less than or equal to all",
      "lteq_any": "less than or equal to any",
      "matches": "matches",
      "matches_all": "matches all",
      "matches_any": "matches any",
      "not_cont": "doesn't contain",
      "not_cont_all": "doesn't contain all",
      "not_cont_any": "doesn't contain any",
      "not_end": "doesn't end with",
      "not_end_all": "doesn't end with all",
      "not_end_any": "doesn't end with any",
      "not_eq": "not equal to",
      "not_eq_all": "not equal to all",
      "not_eq_any": "not equal to any",
      "not_in": "not in",
      "not_in_all": "not in all",
      "not_in_any": "not in any",
      "not_null": "is not null",
      "not_start": "doesn't start with",
      "not_start_all": "doesn't start with all",
      "not_start_any": "doesn't start with any",
      "null": "is null",
      "present": "is present",
      "start": "starts with",
      "start_all": "starts with all",
      "start_any": "starts with any",
      "true": "is true"
    },
    "search": "search",
    "sort": "sort",
    "value": "value"
  },
  "recipe_change": {
    "cancel": "Cancel",
    "change_meal": "Change to this meal",
    "empty_favorites_list": "Currently there are no recipes marked as favourite.",
    "empty_recommended_list": "Please %{href} in order to find a suitable replacement.",
    "no_results": "No results were found for",
    "no_results_filters": "No results were found for the selected filters.",
    "replace": "Replace",
    "replace_meal": "Replace meal",
    "see_details": "See details",
    "select_more_ingredients": "select more ingredients"
  },
  "recipes": {
    "recipe": {
      "calories_per_serving": "Calories",
      "carbohydrates_per_serving": "Carbs",
      "difficulty": "Difficulty",
      "fat_per_serving": "Fat",
      "ingredients": "Ingredients",
      "instructions": "Instructions",
      "num_servings": "Servings",
      "nutrition": "Nutrition information",
      "nutrition_info": "For women aim for 1200-1400 calories a day for weight loss, and men 1800-2200; depending on your height, activity level and body type.",
      "preparation_time_in_minutes": "Cooking Time",
      "print": "Print",
      "protein_per_serving": "Protein"
    },
    "show": {
      "back": "Back"
    }
  },
  "sessions": {
    "destroy": {
      "ok": "session closed successfully"
    },
    "new": {
      "forgot": "Can't sign in? Recover password",
      "submit": "Sign in",
      "subtitle": "Use your email and password to access to your Keto Weekly Plan",
      "title": "Sign in"
    }
  },
  "settings": {
    "cancel_subscriptions": {
      "create": {
        "already_unsubscribed": "No active subscription found.",
        "error": "Failed to cancel subscription, please contact support.",
        "ok": "You are no longer subscribed"
      },
      "show": {
        "back": "Go back",
        "cancel_subscription": "Cancel subscription",
        "subtitle": "If you cancel this subscription, You won't be able to access to your Weekly plan.",
        "title": "Are you sure you would like to cancel?"
      }
    },
    "dietary_preferences": {
      "edit": {
        "allowed_foods_meats": "Protein",
        "allowed_foods_vegetables": "Vegetables",
        "errors": {
          "one_protein": "You need to select at least 1 item from the protein column",
          "one_vegetable": "You need to select at least 1 vegetable"
        },
        "failed": "Please select more ingredients in order to find suitable recipes",
        "modal": {
          "body": "The meal plan will be regenerated and today's meals will update.",
          "cancel": "Cancel",
          "continue": "Continue",
          "title": "Are you sure you want to update your meal plan?"
        },
        "other": "Other Ingredients",
        "regenerating": "Your meal plan is currently being regenerated. Please come back later.",
        "subtitle": "Select ingredients you would like to be included in your meal plan",
        "title": "Dietary Preferences",
        "update_plan": "Update Plan"
      },
      "update": {
        "already_regenerating": "Your meal plan is currently already being regenerated. Please wait for the regeneration to finish.",
        "failed": "Please select more ingredients in order to find suitable recipes",
        "ko": "There was an error updating your meal plan.",
        "ok": "Meal plan has been updated successfully."
      }
    },
    "menu": {
      "dietary_preferences": "Dietary Preferences",
      "plan": "Bill",
      "profile": "Profile",
      "sign_out": "Sign out",
      "weekly_plan": "Weekly Plan"
    },
    "passwords": {
      "edit": {
        "current_password": "Current Password",
        "new_password": "New Password",
        "repeat_new_password": "Repeat New Password",
        "submit": "Save",
        "title": "Password Settings"
      },
      "update": {
        "ko": "There was an error updating your password",
        "ok": "Password successfully updated"
      }
    },
    "plans": {
      "show": {
        "active_until": "Active until",
        "billing": "Billing",
        "edit_profile": "Edit profile",
        "last_bill": "Last Bill",
        "next_bill": "Next Bill",
        "product": "Product",
        "purchase_date": "Purchase date",
        "subscription_cancel": "Cancel Subscription",
        "subscription_canceled": "Subscription canceled",
        "subtitle": "Your bill information",
        "title": "Settings",
        "unsubscribed": "Unsubscribed"
      }
    },
    "profiles": {
      "edit": {
        "change_password": "Change Password",
        "email": "Email Address",
        "email_placeholder": "Enter your email address",
        "full_name_placeholder": "Enter your full name",
        "submit": "Save",
        "title": "Profile"
      },
      "update": {
        "ko": "There was an error updating your profile.",
        "ok": "Profile updated successfully."
      }
    }
  },
  "sign_ups": {
    "sign_up_html": "sign_up_html"
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "support_email": "support@simpleketodiet.com",
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "upsell_mailer": {
    "checkout": {
      "body_html": "<p>Hi %{name},</p>\n<p>Thank you for adding <b>%{product_name}</b> to your order!</p>\n<p>You can access your ebook <a href=%{download_link}> here </a><p>\n<br>\n\n<p>Best,</p>\n<p>SimpleKeto Team</p>",
      "subject": "Download your %{product_name}"
    }
  },
  "upsells": {
    "add_to_order": "Add to order",
    "delivery": {
      "action_is_important": "Action is an important part of any big change. If you feel ready to start achieving your weight goals, you shouldn't wait!",
      "available_now": "This limited time offer is only available NOW, it will not be available again later.",
      "current_eta": "Current estimated delivery time",
      "current_spot": "Your current spot in the queue",
      "deliver_1h": "Deliver My Plan in 1 Hour",
      "express_delivery_service": "Express Delivery Service ",
      "feeling_inspired": "Feeling inspired",
      "get_meal_plan": "and get your meal plan in just 1-hour for only ",
      "hours": "hours",
      "how_does_it_work_steps": [
        {
          "index": "01",
          "text": "Immediately after payment, a dietitian will start to put your custom plan together."
        },
        {
          "index": "02",
          "text": "You'll receive it in less than 1-hour to your email, guaranteed."
        },
        {
          "index": "03",
          "text": "Start your plan today, without any delays."
        }
      ],
      "how_does_it_work_title": "How does it work?",
      "jump_in_front": "Jump to the front of the line with ",
      "minutes": "minutes",
      "no_thanks": "No, thanks, I want to wait 26 Hours.",
      "offer_reserved": "Offer reserved for",
      "one_time_payment": "One time payment, 1-hour delivery, no hidden fees.",
      "our_nutritionists": "Our nutritionists carefully review every plan before sending it. This can take some time, usually 24h - 72hrs",
      "our_team": "Our team always works tirelessly to work on your custom Meal Plan as fast as possible. But with our Express Delivery Service you'll get a dedicated nutritionist to work on your plan and get it ready in less than 1-hour, guaranteed.",
      "popular": "popular",
      "seconds": "seconds",
      "slots_left": "slots left",
      "to_start_immediately": "to start immediately?",
      "usd": "USD",
      "we_limit": "We limit our Express Delivery Service to just 50 orders a day to ensure highest quality and on-time delivery."
    },
    "dessert": {
      "dessert_ebook": "Our dessert recipe ebook allows you to add sweets to your diet without sacrificing your progress.",
      "dessert_recipes": "These Keto dessert recipes have been created by experts to actually promote ketosis and help you achieve your desired results even faster. Inside the book you'll find easy to follow recipes and guidance on when to eat them.",
      "download_instantly": "Download instantly available after purchase.",
      "expires": "Offer expires in",
      "get_recipes": "Get 20 delicious keto friendly dessert recipes",
      "included": "What's included",
      "included_steps": [
        {
          "index": "01",
          "text": "Dozens of simple Keto friendly dessert recipes."
        },
        {
          "index": "02",
          "text": "Boost your progress towards your goal while enjoying a treat."
        },
        {
          "index": "03",
          "text": "Guidelines on how and when to add desserts to your custom Meal Plan."
        },
        {
          "index": "04",
          "text": "Variety of desserts including cake, cookies, brownies, pudding, truffles and more."
        },
        {
          "index": "05",
          "text": "Instant access, delivered via email."
        }
      ],
      "keto_book": "Keto Dessert Recipe eBook",
      "limited_time": "This limited time offer is only available NOW, it will not be available again later.",
      "most_people_fail": "Did you know that most people fail at following diets when they're too restrictive?",
      "no_thanks": "No, thanks, I don't like sweet treats.",
      "one_time": "One time payment, lifetime access, no hidden fees.",
      "popular": "popular",
      "recipe_ebook": "Keto Dessert Recipe eBook",
      "satisfy_cravings": "Satisfy your cravings while still burning fat with our Keto Dessert Book"
    },
    "guide": {
      "boost_results": "Boost your results by understandanding exactly how and why your meal plan works perfectly with our Ultimate Keto Guide.",
      "included_steps": [
        {
          "index": "01",
          "text": "Do’s and Don’ts of Keto Diets - add flexibility to your Meal Plan."
        },
        {
          "index": "02",
          "text": "Guidelines, tips and tricks to boost your results."
        },
        {
          "index": "03",
          "text": "Scientific concepts explained in an approachable way."
        },
        {
          "index": "04",
          "text": "Unlock the ability to safely customize your meal plan."
        },
        {
          "index": "05",
          "text": "Instant access, delivered via email."
        }
      ],
      "keto_guide": "Ultimate Keto Guide eBook",
      "master": "Want to maximize your Keto results?",
      "meal_plan": "Learn the science behind Keto so you can better understand what’s happening to your body.",
      "skip": "No thanks, I don’t want optimal results.",
      "start": "Navigate the process of ketosis with confidence and rest assured your body is optimally burning fat with the Ultimate Keto Guide - the perfect companion guide for your Keto Meal Plan."
    },
    "names": {
      "special_offer_desserts": "Keto Desserts",
      "special_offer_express": "Express Delivery",
      "special_offer_guide": "Ultimate Keto Guide",
      "special_offer_workout": "Workout Plan"
    },
    "order_not_complete": "Wait! Your order is not complete yet",
    "progress": {
      "order_complete": "Order complete",
      "order_submitted": "Order submitted",
      "special_offer": "Special offer"
    },
    "workout": {
      "12_week_pan": "12-Week Workout Plan",
      "achieve_goals": "Achieve your weight goals much faster with simple exercises anyone can do at home, without any dedicated equipment.",
      "boost_results": "Get faster results with our Rapid Fat Loss Workout Plan.",
      "complement": "This 12-Week Workout Plan perfectly complements your customized meal plan to enhance ketosis.",
      "included_steps": [
        {
          "index": "01",
          "text": "Dozens of exercises suited for everyone, from beginners to experts."
        },
        {
          "index": "02",
          "text": "Clear instructions for each, with tips on how to get the best results."
        },
        {
          "index": "03",
          "text": "Nutritional advice to deepen your understanding of Keto and complement your workout."
        },
        {
          "index": "04",
          "text": "No dedicated equipment required, only items found at home."
        },
        {
          "index": "05",
          "text": "Instant access, delivered via email."
        }
      ],
      "no_thanks": "No thanks, I don’t want quicker results.",
      "previous_plan": "Sale Price",
      "workout_plan": "Our Workout Plan has been designed by experts and is compatible with all levels of physical fitness. Additionally, you’ll get nutritional advice on how to perfectly pair your Meal Plan with your workout routine, to get the most out of your Keto journey!"
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "next": "Next &rsaquo;",
      "previous": "&lsaquo; Prev",
      "truncate": "&hellip;"
    }
  },
  "weekly_plan_mailer": {
    "notify_finished": {
      "body_html": "<p>Good news. Your personalized SimpleKeto Meal Plan is ready!</p>\n<p>Our nutritionist has reviewed your profile and approved your custom Keto Meal Plan. You can access and begin immediately!</p>\n<p><b>Your password is:</b> %{default_password} </p>\n<p><a href=\"%{url}\">Click here to access your meal plan</a></p>\n<p>If you have any questions, feel free to email us at <a href=\"mailto:support@simpleketodiet.com\">support@simpleketodiet.com</a> and a member of our team will be happy to assist you.</p>\n<p>We wish you all the best in your journey to a leaner, healthier you!</p>\n<br>\n<p>Best,</p>\n<p>SimpleKeto Team</p>",
      "body_txt": "Your personalized Simple Keto Diet is ready!\nOur nutritionist has now overviewed your keto requirements and we have crafted a tailored-made keto plan that you can access now.\n\n%{url}\n\nWe wish you all best best in achieving your health goals.\n\nIf you have any questions, please send an email to support@simpleketodiet.com and a member of our team will be happy to help.\n\nWe wish you all best in your journey to a leaner, healthier you!",
      "subject": "Your SimpleKeto meal plan is ready"
    }
  },
  "weekly_plans": {
    "grocery_list": {
      "title": "Grocery List - Week %{week}"
    },
    "index": {
      "breakfast": "Breakfast",
      "day": "Day %{num_day}",
      "dinner": "Dinner",
      "download_grocery_list": "Download grocery for week %{number_word}",
      "lunch": "Lunch",
      "meal_presenter": {
        "day": "Day %{num_day}"
      },
      "name_title": "%{name}'s",
      "placeholder": {
        "main_html": "Please %{href} in order to find suitable %{meal_type} recipes",
        "regenerating": "Plan is being regenerated. Please refresh or come back later.",
        "select_more_ingredients": "select more ingredients"
      },
      "read_more": "Read more",
      "settings": "Settings",
      "snack": "Snack",
      "title": "Meal Plan",
      "weekly_title": "Week %{num_week}"
    },
    "working_on_it": {
      "body_html": "<p>Our experts are working on your personalized meal plan. This process usually takes up to 24 hours. We will send you an email with the link when it is ready!</p>",
      "settings": "Settings",
      "title": "Your meal plan is almost ready!"
    }
  }
});
