// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("channels");
window.$ = window.jQuery = require("jquery");

import "bootstrap";
import "../application/data-is-sticky.js";
import "../application/meal_plan_form.js";
import "../application/long_meal_plan_form.js";
import "../application/checkout_form.js";
import "../application/countdown.js";
import "../application/enter_next_step.js";
import "../application/weekly_plan.js";
import "../application/recipe.js";
import "../application/image-background-replace.js";
import "../application/plan-selector.js";
import "../application/carousel.js";
import "../application/paypal.js";
import "../application/upsells.js";
import "../bundles/i18n/en.js";
import "../application/replace_recipe.js";
import "../application/login.js";
import "../stylesheets/styles.css";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.printPage = function() {
  window.print();
};
