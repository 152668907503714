import Vue from "vue/dist/vue.esm";
import Vuelidate from "vuelidate";
import { required, between, maxValue, email } from "vuelidate/lib/validators";

Vue.use(Vuelidate);

document.addEventListener("DOMContentLoaded", () => {
  if ($("#long-meal-plan-form").length) {
    initializeMealPlanForm();
  }
});

function initializeMealPlanForm() {
  new Vue({
    el: "#long-meal-plan-form",
    data: {
      step: 1,
      startingStep: 1,
      steps: [
        "home",
        "knowledge",
        "goal",
        "current-weight",
        "goal-weight",
        "age-height",
        "body-type",
        "exercise",
        "achieved-weight",
        "consume",
        "smoker",
        "stomach",
        "medical",
        "energy",
        "sleep",
        "bed-time",
        "water",
        "prep-time",
        "meats",
        "vegetables",
        "allergens",
        "products",
        "loading",
        "email"
      ],
      // Not all steps count for the percentage calculation. Excluding [home, loading, email]
      excludedFromPercentageCalculationSteps: ["home", "loading", "email"],
      vegetarian: false,
      loader: {
        interval_time: 200,
        percentage: 0,
        step: 0
      },
      mealPlan: {
        age: 0,
        height_cm: null,
        height_in: null,
        weight_kg: null,
        target_weight_kg: null,
        preferred_weight_measurement_system: null,
        preferred_target_weight_measurement_system: null,
        preferred_height_measurement_system: null
      },
      height_detailed_in: null,
      height_detailed_feet: null
    },
    validations() {
      return {
        mealPlan: {
          age: { required, between: between(18, 150) },
          height_cm: { required, between: between(100, 300) },
          weight_kg: { required, between: between(40, 300) },
          target_weight_kg: {
            required,
            between: between(40, 300),
            maxValue: maxValue(this.mealPlan.weight_kg)
          },
          weight_lb: { required, between: between(80, 800) },
          target_weight_lb: {
            required,
            between: between(80, 800),
            maxValue: maxValue(this.mealPlan.weight_lb)
          },
          email: { required, email }
        },
        height_detailed_in: { required, between: between(0, 11) },
        height_detailed_feet: { required, between: between(3, 9) }
      };
    },
    methods: {
      async nextStep() {
        await new Promise(r => setTimeout(r, 500));

        if (this.step === this.steps.indexOf("body-height")) {
          this.$v.mealPlan.age.$touch();
          this.$v.mealPlan.height_cm.$touch();
          this.$v.height_detailed_feet.$touch();
          this.$v.height_detailed_in.$touch();

          this.$v.mealPlan.weight_kg.$touch();
          this.$v.mealPlan.target_weight_kg.$touch();
          this.$v.mealPlan.weight_lb.$touch();
          this.$v.mealPlan.target_weight_lb.$touch();

          if (this.mealPlan.preferred_height_measurement_system === "in") {
            this.changeHeightInches();
          }

          if (this.validMeasurements()) {
            this.step += 1;
          }
        } else if (this.step === this.steps.indexOf("email")) {
          this.$v.mealPlan.email.$touch();

          if (this.isValid(this.$v.mealPlan.email)) {
            $("form#new_meal_plan").submit();
          }
        } else if (
          this.step === this.steps.indexOf("current-weight") ||
          this.step === this.steps.indexOf("goal-weight")
        ) {
          if (this.validMeasurementsWeight()) {
            this.step += 1;
          }
        } else {
          this.step += 1;
        }

        if (this.step === this.steps.indexOf("loading")) {
          this.startLoading();
        }

        if (this.step !== this.steps.length) {
          this.pushHistory();
        }
      },
      setStep() {
        var params = new URLSearchParams(window.location.search);

        if (params.get("step")) {
          this.step = parseInt(params.get("step"));
        } else {
          this.step = this.startingStep;
        }
      },
      previousStep() {
        if (this.step === this.startingStep) {
          window.location = "/";
        } else {
          this.step -= 1;
          this.pushHistory();
        }
      },
      pushHistory() {
        var params = new URLSearchParams(window.location.search);
        params.set("step", this.step);
        var stepName = this.steps[this.step || this.startingStep];
        var pathname = this.pathNamePrefix() + stepName;

        history.pushState(
          { step: this.step },
          document.title,
          pathname + "?" + params.toString()
        );
      },
      pathNamePrefix() {
        switch (this.step) {
          case this.steps.indexOf("email"):
            return "/account/";
          default:
            return "/quiz/";
        }
      },
      changeAllowedMeats(event) {
        var vegetarianOption = "vegetarian";
        var clickedElementAndHaveUnselectedVegetarian =
          this.mealPlan.allowed_foods_meats.includes(vegetarianOption) &&
          event.target.value !== vegetarianOption;

        if (clickedElementAndHaveUnselectedVegetarian) {
          this.mealPlan.allowed_foods_meats = this.mealPlan.allowed_foods_meats.filter(
            value => value !== vegetarianOption
          );
        } else if (event.target.value == vegetarianOption) {
          if (this.mealPlan.allowed_foods_meats.includes(vegetarianOption)) {
            this.mealPlan.allowed_foods_meats = [vegetarianOption];
          } else {
            this.mealPlan.allowed_foods_meats = [];
          }
        }
      },
      changeWeightMeasurementSystem() {
        if (this.mealPlan.preferred_weight_measurement_system === "kg") {
          this.mealPlan.preferred_target_weight_measurement_system = "kg";
          if (this.mealPlan.weight_lb !== null) {
            this.mealPlan.weight_kg = this.mealPlan.weight_lb;
          }
        } else if (
          this.mealPlan.preferred_weight_measurement_system === "lbs"
        ) {
          this.mealPlan.preferred_target_weight_measurement_system = "lbs";
          if (this.mealPlan.weight_kg !== null) {
            this.mealPlan.weight_lb = this.mealPlan.weight_kg;
          }
        }
      },
      changeHeightInches() {
        if (this.height_detailed_in && this.height_detailed_feet) {
          this.mealPlan.height_in =
            parseInt(this.height_detailed_in) +
            parseInt(this.height_detailed_feet) * 12;
        }
      },
      changeAllowedFoodsConsumptions(event) {
        var noneOption = "none";
        var clickedElementAndHaveUnselectedNone =
          this.mealPlan.allowed_foods_consumptions.includes(noneOption) &&
          event.target.value !== noneOption;

        if (clickedElementAndHaveUnselectedNone) {
          this.mealPlan.allowed_foods_consumptions = this.mealPlan.allowed_foods_consumptions.filter(
            value => value !== noneOption
          );
        } else if (event.target.value == noneOption) {
          if (this.mealPlan.allowed_foods_consumptions.includes(noneOption)) {
            this.mealPlan.allowed_foods_consumptions = [noneOption];
          } else {
            this.mealPlan.allowed_foods_consumptions = [];
          }
        }
      },
      changeConditions(event) {
        var noneOption = "none";
        var clickedElementAndHaveUnselectedNone =
          this.mealPlan.allowed_foods_consumptions.includes(noneOption) &&
          event.target.value !== noneOption;

        if (clickedElementAndHaveUnselectedNone) {
          this.mealPlan.conditions = this.mealPlan.conditions.filter(
            value => value !== noneOption
          );
        } else if (event.target.value == noneOption) {
          if (this.mealPlan.conditions.includes(noneOption)) {
            this.mealPlan.conditions = [noneOption];
          } else {
            this.mealPlan.conditions = [];
          }
        }
      },
      showNext() {
        switch (this.step) {
          case this.steps.indexOf("current-weight"):
            if (this.mealPlan.preferred_weight_measurement_system === "kg") {
              return !!this.mealPlan.weight_kg;
            } else if (
              this.mealPlan.preferred_weight_measurement_system === "lbs"
            ) {
              return !!this.mealPlan.weight_lb;
            }
          case this.steps.indexOf("goal-weight"):
            if (
              this.mealPlan.preferred_target_weight_measurement_system === "kg"
            ) {
              return !!this.mealPlan.target_weight_kg;
            } else if (
              this.mealPlan.preferred_target_weight_measurement_system === "lbs"
            ) {
              return !!this.mealPlan.target_weight_lb;
            }
          case this.steps.indexOf("consume"):
            return true;
          case this.steps.indexOf("stomach"):
            return true;
          case this.steps.indexOf("medical"):
            return true;
          case this.steps.indexOf("meats"):
            var exceptionOption = "vegetarian";

            if (this.mealPlan.allowed_foods_meats === [exceptionOption]) {
              return true;
            } else {
              return (
                this.mealPlan.allowed_foods_meats.length > 0 &&
                !this.mealPlan.allowed_foods_consumptions.includes(
                  exceptionOption
                )
              );
            }
          case this.steps.indexOf("vegetables"):
            return true;
          case this.steps.indexOf("allergens"):
            return true;
          case this.steps.indexOf("products"):
            return true;
          case this.steps.indexOf("age-height"):
            return this.validMeasurements();
          case this.steps.indexOf("email"):
            return this.isValid(this.$v.mealPlan.email);
          default:
            return false;
        }
      },
      nextDisabled() {
        switch (this.step) {
          case this.steps.indexOf("meats"):
            return (
              this.mealPlan.allowed_foods_meats.length === 0 &&
              this.vegetarian === false
            );
          case this.steps.indexOf("vegetables"):
            return this.mealPlan.allowed_foods_vegetables.length === 0;
          case this.steps.indexOf("allergens"):
            return this.mealPlan.allowed_foods_allergens.length === 0;
          default:
            return false;
        }
      },
      validMeasurements() {
        const validAge = this.isValid(this.$v.mealPlan.age);

        switch (this.mealPlan.preferred_height_measurement_system) {
          case "cms":
            return validAge && this.isValid(this.$v.mealPlan.height_cm);
          case "in":
            return (
              validAge &&
              this.isValid(this.$v.height_detailed_in) &&
              this.isValid(this.$v.height_detailed_feet)
            );
        }
      },
      validMeasurementsWeight() {
        switch (this.mealPlan.preferred_weight_measurement_system) {
          case "kg":
            if (this.step === this.steps.indexOf("current-weight")) {
              this.$v.mealPlan.weight_kg.$touch();
              return this.isValid(this.$v.mealPlan.weight_kg);
            }
            this.$v.mealPlan.target_weight_kg.$touch();
            return this.isValid(this.$v.mealPlan.target_weight_kg);
          case "lbs":
            if (this.step === this.steps.indexOf("current-weight")) {
              this.$v.mealPlan.weight_lb.$touch();
              return this.isValid(this.$v.mealPlan.weight_lb);
            }
            this.$v.mealPlan.target_weight_lb.$touch();
            return this.isValid(this.$v.mealPlan.target_weight_lb);
        }
      },
      progressbarPercentage() {
        const totalSteps =
          this.steps.length -
          this.excludedFromPercentageCalculationSteps.length;
        return (this.step / totalSteps) * 100;
      },
      status(validation) {
        if (validation.$error) {
          return "is-invalid";
        } else if (validation.$dirty) {
          return "is-valid";
        }
      },
      statusDouble(validation1, validation2) {
        if (validation1.$error || validation2.$error) {
          return "is-invalid";
        } else if (validation1.$dirty && validation2.$dirty) {
          return "is-valid";
        }
      },
      startLoading() {
        const that = this;
        const loader = this.loader;

        function percentageToDegrees(percentage) {
          return (percentage / 100) * 360;
        }

        const interval = setInterval(function() {
          loader.step = loader.step += 1;
          const next_percentage_value =
            loader.percentage + Math.floor(Math.random() * 10 + 1);
          loader.percentage = Math.min(next_percentage_value, 100);

          $(".progress-circle").each(function() {
            var value = $(this).attr("data-value");
            var left = $(this).find(".progress-left .progress-bar");
            var right = $(this).find(".progress-right .progress-bar");
            if (value > 0) {
              if (value <= 50) {
                right.css(
                  "transform",
                  "rotate(" + percentageToDegrees(value) + "deg)"
                );
              } else {
                right.css("transform", "rotate(180deg)");
                left.css(
                  "transform",
                  "rotate(" + percentageToDegrees(value - 50) + "deg)"
                );
              }
            }
          });

          if (loader.percentage >= 100) {
            this.clearInterval(interval);
            that.step += 1;
            that.pushHistory();
          }
        }, loader.interval_time);
      },
      isValid(validation) {
        return !validation.$error && validation.$dirty;
      }
    },
    beforeMount() {
      this.mealPlan = JSON.parse(this.$el.dataset.mealPlan);

      if (this.mealPlan.height_in !== null) {
        this.height_detailed_feet = parseInt(this.mealPlan.height_in / 12);
        this.height_detailed_in = parseInt(
          Math.round(this.mealPlan.height_in - this.height_detailed_feet * 12)
        );
      }
    },
    mounted() {
      window.onpopstate = () => {
        this.setStep();
      };
    }
  });
}
