import Vue from "vue/dist/vue.esm";
import ReplaceHelper from "../mixins/replace_helper.js";
import {
  compareDesc,
  format,
  addWeeks,
  isSameDay,
  addDays,
  endOfWeek,
  startOfWeek
} from "date-fns";

document.addEventListener("DOMContentLoaded", () => {
  const weeklyPlan = {
    init: function() {
      if ($("#weekly-plan").length) this.initPlans();
    },

    initPlans: function() {
      new Vue({
        el: "#weekly-plan",
        mixins: [ReplaceHelper],
        data: {
          purchaseDate: null,
          weekdays: [],
          selectedWeek: null,
          selectedDay: null,
          startOfWeek: null,
          endOfWeek: null,
          weekIterval: null,
          isLoading: false,
          selectedDate: null,
          urlParams: new URLSearchParams(window.location.search)
        },

        computed: {
          today() {
            if (this.weekdays.find(day => day.date === new Date().getDate())) {
              return this.weekdays.find(
                day => day.date === new Date().getDate()
              );
            } else if (this.selectedWeek === 0) {
              return this.weekdays.find(day => day.isDisabled == false);
            }
            return this.weekdays[0];
          }
        },

        created: async function() {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          document.cookie = `timeZone=${timeZone};`;

          const response = await fetch("/api/purchase_date", {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          });
          const responseData = await response.json();
          this.purchaseDate = new Date(responseData.date);

          this.computeWeekDays();
          let day_date = this.urlParams.has("day")
            ? parseInt(this.urlParams.get("day"))
            : this.today.date;
          this.selectDay(day_date, true);
          this.weekIterval = this.getWeekIterval();
        },

        methods: {
          isBeforePurchaseDate(date) {
            const purchaseDate = this.purchaseDate.setHours(0, 0, 0, 0);
            const hourlessDate = date.setHours(0, 0, 0, 0);
            if (compareDesc(purchaseDate, hourlessDate) < 0) {
              return true;
            }
            return false;
          },

          selectDay(day, hideMsg = false) {
            window.history.replaceState(null, null, "?day=" + day);
            this.weekdays = this.weekdays.map(function(item) {
              item.dateActive = item.date === day;
              return item;
            });

            const dayiIdx = this.weekdays.findIndex(x => x.date === day) + 1;
            this.selectedDay = this.selectedWeek * 7 + dayiIdx;
            this.selectedDate = day;

            $(".day:not(.skd--placeholder)").addClass("d-none");
            $(`.day-${this.selectedDay}`).removeClass("d-none");

            if (!hideMsg) {
              $(".alert-replace").addClass("d-none");
            }
          },

          getSelectedWeek() {
            const href = window.location.pathname;
            const myRegexp = /weekly_plans(.*)/;
            this.selectedWeek = myRegexp.exec(href)[1].replace("/", "");

            if (!this.selectedWeek || this.selectedWeek == 1) {
              this.selectedWeek = 0;
            } else {
              this.selectedWeek = this.selectedWeek - 1;
            }
          },

          computeWeekDays() {
            const startDate = addWeeks(
              new Date(this.purchaseDate),
              this.selectedWeek
            );
            this.startOfWeek = startOfWeek(new Date(startDate), {
              weekStartsOn: 1
            });
            this.endOfWeek = endOfWeek(new Date(startDate), {
              weekStartsOn: 1
            });
            let current = this.startOfWeek;
            while (current <= this.endOfWeek) {
              this.weekdays.push(new Date(current));
              current = addDays(new Date(current), 1);
            }

            this.weekdays = this.weekdays.map(item => {
              const obj = {
                name: new Date(item).toLocaleDateString(undefined, {
                  weekday: "short"
                }),
                date: new Date(item).getDate(),
                dateActive: isSameDay(new Date(item), new Date()),
                isDisabled: this.isBeforePurchaseDate(item)
              };
              return obj;
            });
          },

          getWeekIterval() {
            let country = document.getElementById("country").dataset.country;
            let date_format = country == "US" ? "MMM do" : "do MMM";
            const start = format(new Date(this.startOfWeek), date_format);
            const end = format(new Date(this.endOfWeek), date_format);
            return `${start} - ${end}`;
          }
        },

        mounted() {
          this.isLoading = true;
          this.getSelectedWeek();
          this.isLoading = false;
        }
      });
    }
  };

  weeklyPlan.init();
});
