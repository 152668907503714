document.addEventListener("DOMContentLoaded", () => {
  const stickyElm = document.querySelector("[data-is-sticky]");

  if (!!stickyElm) {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("is-sticky", e.intersectionRatio < 1);
        console.log(e);
      },
      { threshold: [1] }
    );

    observer.observe(stickyElm);
  }
});
