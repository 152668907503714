import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", () => {
  if ($(".carousel").length) {
    normalizeCarrouselSlides();
  }
  if ($(".splide-carousel").length) {
    splideCarousel();
  }
});

function splideCarousel() {
  new Splide("#carouselRecipe", {
    type: "loop",
    perPage: 4,
    perMove: 1,
    focus: 0,
    speed: 800,
    breakpoints: {
      640: {
        perPage: 1
      },
      768: {
        perPage: 2
      },
      992: {
        perPage: 3
      }
    }
  }).mount();
}

function normalizeCarrouselSlides() {
  $(".carousel").each(function() {
    var items = $(".carousel-item", this);

    items.css("min-height", 0);

    var maxHeight = Math.max.apply(
      null,
      items
        .map(function() {
          return $(this).outerHeight();
        })
        .get()
    );

    items.css("min-height", maxHeight + "px");
  });
}
